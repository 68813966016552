import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
// import icon1 from 'assets/img/spot-illustrations/13.png';
// import icon1Dark from 'assets/img/spot-illustrations/dark_13.png';
// import icon2 from 'assets/img/spot-illustrations/14.png';
// import icon2Dark from 'assets/img/spot-illustrations/dark_14.png';
// import icon3 from 'assets/img/spot-illustrations/15.png';
// import icon3Dark from 'assets/img/spot-illustrations/dark_15.png';
// import icon4 from 'assets/img/spot-illustrations/16.png';
// import icon4Dark from 'assets/img/spot-illustrations/dark_16.png';
import { BadgeBg } from "components/base/Badge";
import bg8 from "assets/img/bg/8.png";
import darkBg8 from "assets/img/bg/8-dark.png";
import bg9 from "assets/img/bg/9.png";
import darkBg9 from "assets/img/bg/9-dark.png";
import bg10 from "assets/img/bg/10.png";
import darkBg10 from "assets/img/bg/10-dark.png";
import bg11 from "assets/img/bg/bg-11.png";
import darkBg11 from "assets/img/bg/bg-11-dark.png";
import rocket from "assets/img/spot-illustrations/rocket.png";
import rocketDark from "assets/img/spot-illustrations/rocket-dark.png";
import bag from "assets/img/spot-illustrations/bag-2.png";
import bagDark from "assets/img/spot-illustrations/bag-2-dark.png";
import star from "assets/img/spot-illustrations/star.png";
import starDark from "assets/img/spot-illustrations/star-dark.png";
import shield from "assets/img/spot-illustrations/shield-2.png";
import shieldDark from "assets/img/spot-illustrations/shield-2-dark.png";

import iconIvory from "assets/img/iper-x/ranks/icons/Ivory.png";
import diamond from "assets/img/iper-x/ranks/icons/diamond.svg";
// import iconIvoryDark from 'assets/img/iper-x/ranks/icons/dark_Ivory.png';
import iconSilver from "assets/img/iper-x/ranks/icons/Silver.png";
// import iconSilverDark from 'assets/img/iper-x/ranks/icons/dark_Silver.png';
import iconGold from "assets/img/iper-x/ranks/icons/Gold.png";
// import iconGoldDark from 'assets/img/iper-x/ranks/icons/dark_Gold.png';
import iconPlatinium from "assets/img/iper-x/ranks/icons/Platinium.png";
// import iconPlatiniumDark from 'assets/img/iper-x/ranks/icons/dark_Platinium.png';



export const pricingBreadcrumbItems: PageBreadcrumbItem[] = [
    {
        label: 'Pages',
        url: '#!'
    },
    {
        label: 'Pricing',
        url: '#!',
        active: true
    }
];

export type Feature = {
    id: string;
    label: string;
    new?: boolean;
};

export interface PricingColumn {
    id: number;
    title: string;
    icon: string;
    iconDark: string;
    description: string;
    price: number;
    features: string[];
    notFeatures: string[];
    selected?: boolean;
}

export const pricingColumnFeatures: Feature[] = [
    {
        id: '10_percent_rate_on_license',
        label: 'Remise sur licences 10% '
    },
    {
        id: '15_percent_rate_on_license',
        label: 'Remise sur licences 15%'
    },
    {
        id: '20_percent_rate_on_license',
        label: 'Remise sur licences 20%'
    },
    {
        id: '25_percent_rate_on_license',
        label: 'Remise sur licences 25%'
    },
    {
        id: '30_percent_rate_on_license',
        label: 'Remise sur licences 30%'
    },

    {
        id: 'certificate_expert',
        label: 'Certification expert (Commerciale et technique)'
    },

    {
        id: 'forum_access',
        label: 'Accès au forum partenaires'
    },

    //

    {
        id: 'demo_licence_4',
        label: 'Licence iperx de démonstration Nfr *4 accès simultanée'
    },
    {
        id: 'demo_licence_8',
        label: 'Licence iperx de démonstration Nfr *8 accès simultanée'
    },

    {
        id: 'demo_licence_16',
        label: 'Licence iperx de démonstration Nfr *16 accès simultanée'
    },

    {
        id: 'demo_licence_32',
        label: 'Licence iperx de démonstration Nfr *32 accès simultanée'
    },


    //

    {
        id: 'cutom_retailer_interface',
        label: 'Interface revendeur personnalisée'
    },

    //

    {
        id: 'support_5_tickets_year',
        label: 'Support 5 tickets par/an'
    },

    {
        id: 'support_10_tickets_year',
        label: 'Support 10 tickets par/an'
    },


    {
        id: 'support_unlimited',
        label: 'Support illimité'
    },

    {
        id: 'support_priority_unlimited',
        label: 'Support prioritaire & illimité'
    },


    {
        id: 'support_basic',
        label: 'Support Basique'
    },
    {
        id: 'support_priority',
        label: 'Support prioritaire'
    },


    {
        id: 'included_host',
        label: 'Hébergement inclus'
    },

    {
        id: 'reseller_registration_website',
        label: 'Inscription du revendeur sur notre site web'
    },

    {
        id: 'leads',
        label: 'Leads iperx'
    },


    {
        id: 'training_qualiopi',
        label: 'Formation technique et commerciale 2 jours 750 € HT/jour Éligible OPCO Qualiopi'
    },

    {
        id: 'commercial_support',
        label: 'Animations commerciales,campagne marketing ,accompagnement commercial'
    },

    {
        id: 'minimum_sales_6000_year',
        label: 'Ventes annuelles minimales 6000 € ht/an'
    },


    {
        id: 'minimum_sales_12000_year',
        label: 'Ventes annuelles minimales 12000 € ht/an'
    },

    {
        id: 'minimum_sales_60000_year',
        label: 'Ventes annuelles minimales 60000 € ht/an'
    },
    {
        id: 'minimum_sales_125000_year',
        label: 'Ventes annuelles minimales 125000 € ht/an'
    },
//

    {
        id: '10_percent_rate_on_host',
        label: 'Marge d\'hébergement 10%'
    },

    {
        id: '15_percent_rate_on_host',
        label: 'Marge d\'hébergement 15%'
    },

    {
        id: '20_percent_rate_on_host',
        label: 'Marge d\'hébergement 20%'
    },

    {
        id: '25_percent_rate_on_host',
        label: 'Marge d\'hébergement 25%'
    },

 //

    {
        id: 'seo',
        label: 'Référencement'
    },

    {
        id: 'sip_request_interoperability',
        label: 'Demande d’interopérabilité de trunk SIP'
    },


    {
        id: 'minimum_annual_sales_1000',
        label: 'Ventes annuelles minimales 1000€'
    },

    {
        id: 'minimum_annual_sales_7500',
        label: 'Ventes annuelles minimales 7500€'
    },

    {
        id: 'minimum_annual_sales_17500',
        label: 'Ventes annuelles minimales 17500€'
    },

    {
        id: 'minimum_annual_sales_35000',
        label: 'Ventes annuelles minimales 35000€'
    },




    {
        id: 'advanced_search',
        label: 'Advanced Search'
    },
    {
        id: 'custom_fields',
        label: 'Custom fields',
        new: true
    },
    {
        id: 'task_dependencies',
        label: 'Task dependencies'
    },
    {
        id: 'private_teams',
        label: 'Private teams & projects'
    }
];

export const pricingGridFeatures: Feature[] = [
    {
        id: 'timeline',
        label: 'Timeline'
    },
    {
        id: 'advanced_search',
        label: 'Advanced Search'
    },
    {
        id: 'custom_fields',
        label: 'Custom fields',
        new: true
    },
    {
        id: 'task_dependencies',
        label: 'Task dependencies'
    },
    {
        id: 'additional-space',
        label: '20TB of additional space'
    },
    {
        id: 'bandwidth',
        label: 'Bandwidth of  Upto 1 Gbps'
    },
    {
        id: 'private_teams',
        label: 'Private teams & projects'
    },
    {
        id: 'early-access',
        label: 'Early Access / Beta Features'
    }
];

export const pricingColumnItems: PricingColumn[] = [
    {
        id: 1,
        title: 'Ivoire',
        icon: iconIvory,
        iconDark: iconIvory,
        description:
            'Niveau d\'entrée, pour les partenaires débutants ou ceux qui n\'ont pas encore atteint un certain seuil d\'activité.',
        price: 6000,
        features: [
            '10_percent_rate_on_license', 'certificate_expert', 'forum_access', 'demo_licence_4', 'cutom_retailer_interface',
            'support_5_tickets_year', 'included_host', 'reseller_registration_website', 'training_qualiopi','minimum_sales_6000_year'
            //'10_percent_rate_on_host', 'support_10_tickets_year', 'seo', 'minimum_annual_sales_1000'
        ],
        notFeatures: ['support_priority', 'leads', 'commercial_support'] //leads
    },
    {
        id: 2,
        title: 'Silver',
        icon: iconSilver,
        iconDark: iconSilver,
        description: 'Un niveau intermédiaire, le partenaires argent peuvent bénéficier de quelques avantages supplémentaires.',
        price: 12000,
        features: [
            '15_percent_rate_on_license', 'certificate_expert', 'forum_access', 'demo_licence_8', 'cutom_retailer_interface',
            'support_10_tickets_year', 'included_host', 'reseller_registration_website', 'leads', 'training_qualiopi', 'commercial_support'
            ,'minimum_sales_12000_year'
       //     '15_percent_rate_on_host', 'support_basic', 'seo', 'leads', 'minimum_annual_sales_7500'
        ],
        notFeatures: ['support_priority']
    },
    {
        id: 3,
        title: 'Gold',
        icon: iconGold,
        iconDark: iconGold,
        description: 'Un niveau plus élevé, réservé aux partenaires ayant démontré un engagement significatif et une performance exceptionnelle.',
        price: 60000,
        selected: true,
        features: [
            '20_percent_rate_on_license', 'certificate_expert', 'forum_access', 'demo_licence_16', 'cutom_retailer_interface',
            'support_unlimited', 'included_host', 'reseller_registration_website', 'leads', 'training_qualiopi', 'commercial_support'
            ,'minimum_sales_60000_year'
         //   '15_percent_rate_on_host', 'support_basic', 'seo', 'leads', 'minimum_annual_sales_17500'
        ],
        notFeatures: ['support_priority']
    },
    {
        id: 4,
        title: 'Platinium',
        icon: iconPlatinium,
        iconDark: iconPlatinium,
        description: 'Le niveau le plus élevé, souvent réservé aux partenaires de premier plan, ayant atteint des objectifs exceptionnels.',
        price: 120000,
        features: [
            '25_percent_rate_on_license', 'certificate_expert', 'forum_access', 'demo_licence_32', 'cutom_retailer_interface',
            'support_priority_unlimited', 'included_host', 'reseller_registration_website', 'leads', 'training_qualiopi', 'commercial_support'
            ,'minimum_sales_125000_year'
           // '20_percent_rate_on_host', 'support_priority', 'seo', 'leads', 'sip_request_interoperability', 'minimum_annual_sales_35000'
        ],
      notFeatures: []
    },
  {
    id: 5,
    title: "Diamond",
    icon: diamond,
    iconDark: diamond,
    description: "Le niveau le plus élevé, souvent réservé aux partenaires de premier plan, ayant atteint des objectifs exceptionnels.",
    price: 200000,
    features: [
      "25_percent_rate_on_license", "certificate_expert", "forum_access", "demo_licence_32", "cutom_retailer_interface",
      "support_priority_unlimited", "included_host", "reseller_registration_website", "leads", "training_qualiopi", "commercial_support"
      , "minimum_sales_125000_year"
      // '20_percent_rate_on_host', 'support_priority', 'seo', 'leads', 'sip_request_interoperability', 'minimum_annual_sales_35000'
        ],
        notFeatures: []
    }
];

export interface PricingGrid {
    id: number;
    title: string;
    description: string;
    img: string;
    imgDark: string;
    bg: string;
    darkBg: string;
    monthlyPrice: number;
    yearlyPrice: number;
    features: string[];
    badge?: {
        label: string;
        badgeBg: BadgeBg;
    };
    selected?: boolean;
}

export const pricingGridItems: PricingGrid[] = [
    {
        id: 1,
        title: 'Startup',
        description: `For individuals who are interested <br/> in giving it a shot first.`,
        img: rocket,
        imgDark: rocketDark,
        bg: bg8,
        darkBg: darkBg8,
        monthlyPrice: 0,
        yearlyPrice: 0,
        features: ['Up to 4 Members', '3 Collaboration projects'],
        selected: true
    },
    {
        id: 2,
        title: 'Standard',
        description:
            'For teams that need to create <br/> project plans with confidence.',
        img: bag,
        imgDark: bagDark,
        bg: bg9,
        darkBg: darkBg9,
        monthlyPrice: 14.99,
        yearlyPrice: 179.88,
        features: [
            'Up to 8 Members',
            'Create & Share libraries',
            '10 Collaboration projects'
        ]
    },
    {
        id: 3,
        title: 'Business Plus',
        description: 'For teams that need to manage <br/> work across initiatives.',
        img: star,
        imgDark: starDark,
        bg: bg11,
        darkBg: darkBg11,
        monthlyPrice: 49.99,
        yearlyPrice: 599.88,
        badge: {
            label: 'recommended',
            badgeBg: 'warning'
        },
        features: [
            'Technical Supports',
            'Up to 20 Members',
            'Create & Share libraries',
            '<span class="fw-bold">Unlimited</span> Collaboration'
        ]
    },
    {
        id: 4,
        title: 'Enterprise',
        description:
            'For organizations that need <br/> additional security and support.',
        img: shield,
        imgDark: shieldDark,
        bg: bg10,
        darkBg: darkBg10,
        monthlyPrice: 149.99,
        yearlyPrice: 1799.88,
        features: [
            '24/7 VIP Support',
            'Automated analytics',
            '<span class="fw-bold">Unlimited</span> Members*',
            'Create & Share libraries',
            'Centralized billing'
        ]
    }
];
