import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import AvatarColor from "components/base/AvatarColor";
import UserForm, { UserData } from "./new/UserForm"; // Import new UserForm

interface UserDetails {
  idUsers: number;
  firstname: string;
  lastname: string;
  mail: string;
  phone: string;
  mobile: string;
  userRole: {
    id: number;
    name: string;
    slug: string;
  };
  company: {
    id: number;
    name: string;
    ceoName: string;
  };
  erpThiersId: number;
  erpUserToken: string;
  contact_id?: number;
  yealinkSiteId?: string;
  fanvilSiteId?: string;
  password: string;
  moduleAccess: { idModule: number; hasAccess: boolean }[];
  features: { idFeature: number; canDo: boolean }[];
}

interface ContactDetails {
  firstname: string;
  lastname: string;
  email: string;
  phone_mobile?: string;
  phone_pro?: string;
}

interface ERPDetails {
  name: string;
  address: string;
  zip: string;
  town: string;
  tva_intra: string;
}

const UserDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState<UserData | null>(null);
  const [contactDetails, setContactDetails] = useState<ContactDetails | null>(null);
  const [erpDetails, setERPDetails] = useState<ERPDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage.");
        setLoading(false);
        return;
      }

      try {
        const userResponse = await fetch(
          `https://rct-backend.ipercom.io/api/User/${id}`,
          {
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (!userResponse.ok) {
          throw new Error(`Error fetching user details: ${userResponse.status}`);
        }

        const userData: UserDetails = await userResponse.json();
        setUserDetails(userData);

        if (userData.contact_id) {
          const contactResponse = await fetch(
            `https://erp.ipercom.io/api/index.php/contacts/${userData.contact_id}`,
            {
              headers: {
                Accept: "application/json",
                DOLAPIKEY: userData.erpUserToken
              }
            }
          );

          if (contactResponse.ok) {
            const contactData: ContactDetails = await contactResponse.json();
            setContactDetails(contactData);
          }
        }

        if (userData.erpThiersId) {
          const erpResponse = await fetch(
            `https://erp.ipercom.io/api/index.php/thirdparties/${userData.erpThiersId}`,
            {
              headers: {
                Accept: "application/json",
                DOLAPIKEY: userData.erpUserToken
              }
            }
          );

          if (erpResponse.ok) {
            const erpData: ERPDetails = await erpResponse.json();
            setERPDetails(erpData);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]);

  const handleEditSave = async (updatedUser: any) => {
    try {
      const response = await fetch(
        `https://rct-backend.ipercom.io/api/User`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(updatedUser)
        }
      );

      if (response.ok) {
        setUserDetails(updatedUser);
        setShowEditModal(false);
      } else {
        console.error("Failed to save user data.");
      }
    } catch (error) {
      console.error("Error saving user data:", error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(
        `https://rct-backend.ipercom.io/api/User/${userDetails?.idUsers}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );

      if (response.ok) {
        navigate(-1);
      } else {
        console.error("Failed to delete user.");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setShowDeleteModal(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container className="my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Button variant="primary" onClick={() => navigate(-1)}>
          ← Retour
        </Button>
        <h2>Détails de l'utilisateur</h2>
        <div>
          <Button
            variant="warning"
            className="me-2"
            onClick={() => setShowEditModal(true)}
          >
            Modifier
          </Button>
          <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
            Supprimer
          </Button>
        </div>
      </div>

      {userDetails && (
        <>
          <Row className="mb-4">
            <Col lg={8}>
              <div className="bg-light p-4 rounded">
                <h5>Informations générales</h5>
                <AvatarColor
                  size="xl"
                  variant="initials"
                  firstName={userDetails.firstname}
                  lastName={userDetails.lastname}
                  className="me-3 mb-3"
                />
                <p><strong>Nom:</strong> {userDetails.firstname} {userDetails.lastname}</p>
                <p><strong>Email:</strong> {userDetails.mail}</p>
                <p><strong>Téléphone:</strong> {userDetails.phone || "N/A"}</p>
                <p><strong>Mobile:</strong> {userDetails.mobile || "N/A"}</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="bg-light p-4 rounded">
                <h5>Détails du Contact</h5>
                {contactDetails ? (
                  <>
                    <p>
                      <strong>Nom :</strong> {contactDetails.firstname || "N/A"}
                    </p>
                    <p>
                      <strong>Email :</strong> {contactDetails.email || "N/A"}
                    </p>
                    <p>
                      <strong>Téléphone Mobile :</strong> {contactDetails.phone_mobile || "N/A"}
                    </p>
                  </>
                ) : (
                  <p>Aucun contact trouvé.</p>
                )}
              </div>
              <div className="mt-4 bg-light p-4 rounded">
                <h5>Détails de la Société</h5>
                {erpDetails ? (
                  <>
                    <p><strong>Nom:</strong> {erpDetails.name || "N/A"}</p>
                    <p><strong>Adresse:</strong> {erpDetails.address || "N/A"}</p>
                    <p><strong>Ville:</strong> {erpDetails.town || "N/A"}</p>
                  </>
                ) : (
                  <p>Aucune société trouvée.</p>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier l'utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userDetails && (
            <UserForm
              user={userDetails} // Use the new UserForm
              onSave={handleEditSave}
              onCancel={() => setShowEditModal(false)}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserDetails;

