import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

interface AddLicenseModalProps {
  show: boolean;
  handleClose: () => void;
  handleSave: (newLicense: { licenseKey: string; reference: string; startDate: string; endDate: string }) => void;
}

const AddLicenseModal: React.FC<AddLicenseModalProps> = ({ show, handleClose, handleSave }) => {
  const [licenseKey, setLicenseKey] = useState("");
  const [reference, setReference] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSubmit = () => {
    const newLicense = {
      licenseKey,
      reference,
      startDate,
      endDate
    };
    handleSave(newLicense);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter une nouvelle licence</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="licenseKey">
            <Form.Label>Clé de licence</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrez la clé de licence"
              value={licenseKey}
              onChange={(e) => setLicenseKey(e.target.value)}
            />
          </Form.Group>
          {/*<Form.Group className="mb-3" controlId="reference">*/}
          {/*  <Form.Label>Référence</Form.Label>*/}
          {/*  <Form.Control*/}
          {/*    type="text"*/}
          {/*    placeholder="Entrez la référence"*/}
          {/*    value={reference}*/}
          {/*    onChange={(e) => setReference(e.target.value)}*/}
          {/*  />*/}
          {/*</Form.Group>*/}
          {/*<Form.Group className="mb-3" controlId="startDate">*/}
          {/*  <Form.Label>Date de début</Form.Label>*/}
          {/*  <Form.Control*/}
          {/*    type="date"*/}
          {/*    value={startDate}*/}
          {/*    onChange={(e) => setStartDate(e.target.value)}*/}
          {/*  />*/}
          {/*</Form.Group>*/}
          {/*<Form.Group className="mb-3" controlId="endDate">*/}
          {/*  <Form.Label>Date de fin</Form.Label>*/}
          {/*  <Form.Control*/}
          {/*    type="date"*/}
          {/*    value={endDate}*/}
          {/*    onChange={(e) => setEndDate(e.target.value)}*/}
          {/*  />*/}
          {/*</Form.Group>*/}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled>
          Ajouter
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLicenseModal;
