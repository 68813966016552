import React from "react";
import { PricingColumn } from "data/pricing";
import { Col } from "react-bootstrap";

interface PricingColumnItemProps {
    pricing: PricingColumn;
}

const RetailerPricingColumnItem = ({pricing}: PricingColumnItemProps) => {
    return (
      <Col xs={1} sm={3} xxl={1} key={pricing.title}>
        <div className="avatar-3xl mb-4">
                <img
                    src={pricing.icon}
                    alt=""
                    className="img-thumbnail bg-white rounded-circle ms-2 mb-0 d-dark-none"
                    width={120}
                    height={96}
                    style={{ objectFit: 'contain' }}
                />
                <img
                    src={pricing.iconDark}
                    alt=""
                    className="img-thumbnail bg-white rounded-circle mb-0 ms-2 d-light-none"
                    width={120}
                    height={96}
                    style={{ objectFit: 'contain' }}
                />
            </div>
            {/* A garder !!! ancien code pour les prix  */}
             {/* <div className="pricing-column-title-box mb-sm-5"></div> */}
        <h3 className="h3">{pricing.title}</h3>
                {/*<p className="text-800 mb-0 pe-3">{pricing.description}</p>
            </div>
            <div className="d-flex align-items-center mb-4">
                {pricing.price === 0 ? (
                    <h3 className="display-3 fw-bolder">Gratuit</h3>
                ) : (
                    <>
                      <h4 className="display-5 fw-bolder">
                            {currencyFormat(pricing.price)}
                        </h4>
                        <h5 className="fs-0 fw-normal ms-1">/ht Annuel*</h5>
                    </>
                )}
            </div>

            <h5 className="mb-4">Ce qui est inclu</h5>

            <ul className="fa-ul" style={{'--fa-li-margin': '1.5em'} as CSSProperties}>
                {pricingColumnFeatures.map(item => {
                    const isFeatureIncluded = pricing.features.includes(item.id);
                    const isNotFeatureIncluded = pricing.notFeatures.includes(item.id);
                    // Check if the feature is included or not
                    if (isFeatureIncluded || isNotFeatureIncluded) {
                        return (
                            <li key={item.id} className={classNames('mb-2 text-500', {
                                'text-900': isFeatureIncluded,
                                'text-gray': isNotFeatureIncluded
                            })}>
          <span className="fa-li me-2 stack-icon-item">
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon
                  icon={faCircle}
                  className={classNames('text-300', {
                      'text-success': isFeatureIncluded
                  })}
              />
              <FontAwesomeIcon
                  icon={isFeatureIncluded ? faCheck : faTimes}
                  inverse
                  className={classNames('text-600 fs-11', {
                      'text-white': isFeatureIncluded
                  })}
              />
            </span>
          </span>
                                {item.label}
                                {/* Render 'New' badge only for included features */}
                                {/* {isFeatureIncluded && item.new && (
                                    <Badge variant="phoenix" bg="primary" className="ms-2 fs-10">
                                        New
                                    </Badge>
                                )}
                            </li>
                        );
                    } else { }
                        // Return null if neither included nor not included
                        return null;
                    }
                })}
            </ul>
 */}

        </Col>
    );
};

export default RetailerPricingColumnItem;
