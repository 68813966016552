import React from "react";
import { Dropdown } from "react-bootstrap";
import Avatar from "components/base/Avatar";
import bg32 from "assets/img/bg/bg-32.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export interface PhoneUser {
  id: number;
  firstname: string;
  lastname: string;
  extension: string;
  phoneUsername: string;
  userFunction: string;
  displayNumber: string;
  avatar: string;
}

interface AvatarDropdownProps {
  user: PhoneUser;
  size?: "m" | "xl";
}

const AvatarDropdown: React.FC<AvatarDropdownProps> = ({ user, size = "m" }) => {
  const avatarUrl = `https://ui-avatars.com/api/?name=${user.firstname}+${user.lastname}`;

  return (
    <Dropdown>
      <Dropdown.Toggle variant="" className="dropdown-caret-none p-0">
        <Avatar src={user.avatar || avatarUrl} size={size} />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="avatar-dropdown-menu p-0 overflow-hidden"
        popperConfig={{ strategy: "fixed" }}
        renderOnMount
      >
        <div className="position-relative">
          <div
            className="bg-holder z-index--1"
            style={{
              backgroundImage: `url(${bg32})`,
              backgroundSize: "auto"
            }}
          />
          {/*/.bg-holder*/}
          <div className="p-3">
            <div className="text-end">

              {/*<Link to={`/ipbx/utilisateurs/details/${user.id}`} className="btn p-0 me-2">*/}
              {/*  <FontAwesomeIcon*/}
              {/*    icon={faUser}*/}
              {/*    className="text-white light"*/}
              {/*  />*/}
              {/*</Link>*/}
              {/*<button className="btn p-0 me-2">*/}
              {/*  <FontAwesomeIcon*/}
              {/*    icon={faUserPlus}*/}
              {/*    className="text-white light"*/}
              {/*  />*/}
              {/*</button>*/}
              {/*<button className="btn p-0">*/}
              {/*  <FontAwesomeIcon*/}
              {/*    icon={faEllipsis}*/}
              {/*    className="text-white light"*/}
              {/*  />*/}
              {/*</button>*/}
            </div>
            <div className="text-center">
              <Avatar
                src={user.avatar || avatarUrl}
                size="xl"
                status="online"
                className="border border-white rounded-circle mx-auto mb-2"
              />
              <h6 className="text-white light">
                {user.firstname} {user.lastname}
              </h6>
              <p className="text-600 fw-semi-bold fs-10 mb-2">
                {user.extension}
              </p>
              <div className="d-flex flex-center mb-3">
                <h6 className="text-white light mb-0">
                  <span className="fw-normal text-300">Position</span>
                  {" "}{user.userFunction}
                </h6>
                <FontAwesomeIcon
                  icon={faCircle}
                  className="text-700 mx-1 fs-9"
                  transform="shrink-10 up-2"
                />
                <h6 className="text-white light mb-0">
                  <span className="fw-normal text-300">Téléphone</span>
                  {" "}{user.displayNumber}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          {/*<div className="p-3 border-bottom">*/}
          {/*<div className="d-flex gap-2">*/}
          {/*  <Button*/}
          {/*    variant="phoenix-secondary"*/}
          {/*    className="btn-icon btn-icon-lg"*/}
          {/*  >*/}
          {/*    <FontAwesomeIcon icon={faPhone} />*/}
          {/*  </Button>*/}
          {/*  <Button*/}
          {/*    variant="phoenix-secondary"*/}
          {/*    className="btn-icon btn-icon-lg"*/}
          {/*  >*/}
          {/*    <FontAwesomeIcon icon={faMessage} />*/}
          {/*  </Button>*/}
          {/*  <Button*/}
          {/*    variant="phoenix-secondary"*/}
          {/*    className="btn-icon btn-icon-lg"*/}
          {/*  >*/}
          {/*    <FontAwesomeIcon icon={faVideo} />*/}
          {/*  </Button>*/}
          {/*  <Button*/}
          {/*    variant="phoenix-primary"*/}
          {/*    startIcon={*/}
          {/*      <FontAwesomeIcon icon={faEnvelope} className="me-2" />*/}
          {/*    }*/}
          {/*    className="ms-auto"*/}
          {/*  >*/}
          {/*    Send Email*/}
          {/*  </Button>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<Nav className="flex-column py-3 border-bottom">*/}
          {/*  <Nav.Link className="">*/}
          {/*    <FeatherIcon*/}
          {/*      icon="clipboard"*/}
          {/*      size={16}*/}
          {/*      className="me-2 text-900 d-inline-block"*/}
          {/*    />*/}
          {/*    <span className="text-1000 flex-1">Assigned Projects</span>*/}
          {/*    <FontAwesomeIcon icon={faChevronRight} className="fs-11" />*/}
          {/*  </Nav.Link>*/}
          {/*  <Nav.Link className="">*/}
          {/*    <FeatherIcon*/}
          {/*      icon="pie-chart"*/}
          {/*      size={16}*/}
          {/*      className="me-2 text-900 d-inline-block"*/}
          {/*    />*/}
          {/*    <span className="text-1000 flex-1">View Activity</span>*/}
          {/*    <FontAwesomeIcon icon={faChevronRight} className="fs-11" />*/}
          {/*  </Nav.Link>*/}
          {/*</Nav>*/}

          <div className="p-3 d-flex justify-content-between">
            <Button variant="link" className="p-0 text-decoration-none">
              <Link to={`/ipbx/utilisateurs/details/${user.id}`} className="btn p-0 me-2">
                Détails
              </Link>
            </Button>
            <Button
              variant="link"
              className="p-0 text-decoration-none text-danger"
              disabled
            >
              Désassigner
            </Button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AvatarDropdown;
