import React, { useEffect, useState } from "react";
import { Accordion, Collapse, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const PermissionsAccordion: React.FC<{
  appModules: { idModule: number; name: string }[];
  moduleFeatures: { idFeature: number; idModule: number; name: string }[];
  userData: {
    moduleAccess: { idModule: number; hasAccess: boolean }[];
    features: { idFeature: number; canDo: boolean }[];
  };
  handleModuleAccessChange: (idModule: number, hasAccess: boolean) => void;
  handleFeatureChange: (idFeature: number, canDo: boolean) => void;
  isCustomizing: boolean;
}> = ({
        appModules,
        moduleFeatures,
        userData,
        handleModuleAccessChange,
        handleFeatureChange,
        isCustomizing
      }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Automatically expand if customizing mode is enabled
  useEffect(() => {
    if (isCustomizing) {
      setIsExpanded(true);
    }
  }, [isCustomizing]);

  if (!appModules || appModules.length === 0) {
    return <p>Aucun module disponible.</p>;
  }

  return (
    <div className="my-4">
      {/* Interactive Title with an Icon */}
      <div
        className="d-flex bg-light align-items-center mb-3"
        style={{
          cursor: "pointer",
          backgroundColor: "#f8f9fa",
          borderRadius: "10px",
          padding: "10px 15px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          transition: "all 0.3s ease",
          border: isExpanded ? "2px solid #007bff" : "2px solid transparent"
        }}
        onClick={() => setIsExpanded(!isExpanded)}
        aria-controls="advanced-settings"
        aria-expanded={isExpanded}
        tabIndex={0}
        onKeyDown={(e) => e.key === "Enter" && setIsExpanded(!isExpanded)}
      >
        <FontAwesomeIcon
          icon={isExpanded ? faChevronDown : faChevronRight}
          style={{
            fontSize: "16px",
            marginRight: "10px",
            color: isExpanded ? "#007bff" : "#6c757d"
          }}
        />
        <span
          style={{
            color: isExpanded ? "#007bff" : "#6c757d",
            fontWeight: "bold"
          }}
        >
          {isExpanded
            ? "Masquer les paramètres avancés"
            : "Afficher les paramètres avancés"}
        </span>
      </div>

      {/* Collapsible Content */}
      <Collapse className=" bg-light rounded"
                in={isExpanded}>
        <div id="advanced-settings ">
          <Accordion>
            {appModules.map((module) => {
              const relatedFeatures = moduleFeatures.filter(
                (feature) => feature.idModule === module.idModule
              );
              const moduleAccess = userData.moduleAccess?.some(
                (m) => m.idModule === module.idModule && m.hasAccess
              );

              return (
                <Accordion.Item key={module.idModule} eventKey={module.idModule.toString()}>
                  <Accordion.Header>{module.name}</Accordion.Header>
                  <Accordion.Body>
                    <Form.Check
                      type="checkbox"
                      label={`Accès ${module.name}`}
                      checked={!!moduleAccess}
                      onChange={(e) =>
                        handleModuleAccessChange(module.idModule, e.target.checked)
                      }
                    />
                    {relatedFeatures.length > 0 ? (
                      relatedFeatures.map((feature) => {
                        const featureAccess = userData.features?.some(
                          (f) => f.idFeature === feature.idFeature && f.canDo
                        );

                        return (
                          <Form.Check
                            key={feature.idFeature}
                            type="checkbox"
                            label={feature.name}
                            checked={!!featureAccess}
                            onChange={(e) =>
                              handleFeatureChange(feature.idFeature, e.target.checked)
                            }
                            className="ms-3"
                          />
                        );
                      })
                    ) : (
                      <p className="ms-3 text-muted">Aucune fonctionnalité disponible pour ce module.</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </Collapse>
    </div>
  );
};

export default PermissionsAccordion;
