import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import React from "react";

// import { PhoneDevicesData } from "../../../../hooks/api/ipbx/usePhoneDevicesDataID";
import DevicesForm from "./DevicesForm";
import AvatarDropdown from "../../../../components/common/ipbx/AvatarDropdown";
import { PhoneDevicesData } from "../../../../hooks/api/ipbx/usePhoneDevices";

interface DevicesTableProps {
  onEdit: (device: PhoneDevicesData) => void;
  onDelete: (deviceId: number) => void;
}

export const devicesTableColumns: ColumnDef<PhoneDevicesData>[] = [
  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "10%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => {
      const {
        name = "Nom non défini",
        privateIpAddress,
        publicIpAddress,
        deviceStatus,
        brand,
        id
      } = row.original;

      // Function to determine status color and text based on brand and deviceStatus
      const getStatusDetails = (status: string | number, brand: string) => {
        // Handle statuses for Yealink
        if (brand === "Yealink") {
          switch (status?.toString().toLowerCase()) {
            case "online":
              return { color: "green", text: "En ligne" };
            case "offline":
              return { color: "red", text: "Hors ligne" };
            case "in progress":
              return { color: "orange", text: "En cours" };
            default:
              return { color: "gray", text: "Statut inconnu" };
          }
        }

        // Handle statuses for Fanvil
        if (brand === "Fanvil") {
          const statusNumber = Number(status); // Ensure status is treated as a number
          switch (statusNumber) {
            case 2:
              return { color: "green", text: "En ligne" };
            case 1:
              return { color: "red", text: "Hors ligne" };
            case 0:
              return { color: "orange", text: "Non configuré" };
            default:
              return { color: "gray", text: "Statut inconnu" };
          }
        }

        // Default case for other brands
        return { color: "gray", text: "Statut inconnu" };
      };

      // Retrieve color and text for the current device's status
      const { color: statusColor, text: statusText } = getStatusDetails(
        deviceStatus || "Statut inconnu",
        brand || "Autre"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Status Indicator with Tooltip */}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{statusText}</Tooltip>}
          >
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: statusColor,
              marginRight: "8px",
              cursor: "pointer"
            }}
          ></span>
          </OverlayTrigger>

          {/* Device Name with Tooltip */}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                <div>
                  <strong>IP Privée:</strong> {privateIpAddress || "Non définie"}
                  <br />
                  <strong>IP Publique:</strong> {publicIpAddress || "Non définie"}
                </div>
              </Tooltip>
            }
          >
            <a
              href={`/ipbx/téléphones/details/${id}`}
              className="text-primary"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              {name}
            </a>
          </OverlayTrigger>
        </div>
      );
    },
  },

  {
    accessorKey: "brandModel",
    header: "Constructeur / Modèle",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => (
      <>
        <div>Constructeur: {row.original.brand}</div>
        <div>Modèle: {row.original.deviceModel}</div>
        <div>Version du firmware: {row.original.firmwareVersion}</div>
      </>
    )
  },

  {
    accessorKey: "macAddressMachineId",
    header: "MAC / Machine ID",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => (
      <>
        <div>{row.original.macAddress}</div>
        <div>{row.original.machineId}</div>
      </>
    )
  },

  {
    accessorKey: "location",
    header: "Location",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "deviceType",
    header: "Type de dispositif",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

  {
    accessorKey: "assignedUsers",
    header: "Utilisateurs",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => {
      const { firstUserId, secondUserId } = row.original;

      const [firstUserData, setFirstUserData] = React.useState<any | null>(null);
      const [secondUserData, setSecondUserData] = React.useState<any | null>(null);
      const [loading, setLoading] = React.useState<boolean>(false);
      const [error, setError] = React.useState<string | null>(null);

      React.useEffect(() => {
        // If both IDs are empty => no fetch
        if (!firstUserId && !secondUserId) return;

        const token = localStorage.getItem("token");
        if (!token) {
          setError("Token not found");
          return;
        }

        // Helper function for fetching a user by ID
        const fetchUser = async (id: number) => {
          const res = await fetch(
            `https://rct-backend.ipercom.io/api/Webrtc/phoneuser/${id}`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`
              }
            }
          );
          if (!res.ok) {
            if (res.status === 404)
              throw new Error(`Utilisateur avec l'ID ${id} non trouvé.`);
            throw new Error(
              `Échec de la récupération de l'utilisateur avec l'ID ${id}.`
            );
          }
          return res.json();
        };

        const doFetch = async () => {
          setLoading(true);
          setError(null);
          try {
            if (firstUserId) {
              const user = await fetchUser(firstUserId);
              setFirstUserData(user);
            }
            if (secondUserId) {
              const user = await fetchUser(secondUserId);
              setSecondUserData(user);
            }
          } catch (err) {
            setError((err as Error).message);
            console.error(err);
          } finally {
            setLoading(false);
          }
        };

        doFetch();
      }, [firstUserId, secondUserId]);

      if (!firstUserId && !secondUserId) {
        return <span className="text-muted">Aucun utilisateur</span>;
      }

      if (loading) return <span>Loading...</span>;
      if (error) return <span style={{ color: "red" }}>Error: {error}</span>;

      // Optionally a helper to concat first + last name
      const getFullName = (u: any) =>
        [u.firstname, u.lastname].filter(Boolean).join(" ") || `Utilisateur #${u.id}`;

      return (
        <div className="d-flex flex-column gap-2">
          {/* FIRST USER ROW */}
          {firstUserId ? (
            firstUserData ? (
              <div className="d-flex align-items-center gap-2">
                {/* Avatar Dropdown */}
                <AvatarDropdown key={firstUserData.id} user={firstUserData} />
                {/* Show text in the table cell */}
                <div style={{ fontSize: "0.85rem" }}>
                  <strong>{getFullName(firstUserData)}</strong>
                  {/* If you also want to show phoneUsername, add it here */}
                  {firstUserData.extension && (
                    <>
                      {" "}
                      - Extension: {firstUserData.extension}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <span className="text-muted">
              Aucun utilisateur (ID {firstUserId})
            </span>
            )
          ) : null}

          {/* SECOND USER ROW */}
          {secondUserId ? (
            secondUserData ? (
              <div className="d-flex align-items-center gap-2">
                <AvatarDropdown key={secondUserData.id} user={secondUserData} />
                <div style={{ fontSize: "0.85rem" }}>
                  <strong>{getFullName(secondUserData)}</strong>
                  {secondUserData.extension && (
                    <>
                      {" "}
                      Extension: {secondUserData.extension}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <span className="text-muted">
              Aucun utilisateur (ID {secondUserId})
            </span>
            )
          ) : null}
        </div>
      );
    }
  },


  {
    id: "leadDropdown",
    header: "Actions",
    meta: {
      headerProps: { style: { width: "12%" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [openFormModal, setOpenFormModal] = React.useState(false);
      const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
      const [currentDevice, setCurrentDevice] = React.useState<
        PhoneDevicesData | undefined
      >(undefined);

      // Handlers for editing and deleting
      const handleEditDevice = (device: PhoneDevicesData) => {
        setCurrentDevice(device);
        setOpenFormModal(true);
      };

      const handleDeleteDevice = (device: PhoneDevicesData) => {
        setCurrentDevice(device); // Set the current device
        setOpenDeleteModal(true); // Open the delete modal
      };


      const handleCloseForm = () => {
        setOpenFormModal(false);
        setCurrentDevice(undefined);
      };

      const handleCloseDelete = () => {
        setOpenDeleteModal(false);
        setCurrentDevice(undefined);
      };

      const handleSaveDevice = (updatedDevice: PhoneDevicesData) => {
        // console.log("Device saved:", updatedDevice);
        // Add logic to update the table or re-fetch the data
        handleCloseForm();
      };

      return (
        <>
          {/* Dropdown with actions */}
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={() => handleEditDevice(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteDevice(original)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          {/* Modal for Editing */}
          <Modal show={openFormModal} onHide={handleCloseForm} centered>
            <Modal.Header closeButton>
              <Modal.Title>Modifier l'Appareil</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DevicesForm
                currentDevice={currentDevice}
                mode="edit"
                onSave={handleSaveDevice}
                onClose={handleCloseForm}
              />
            </Modal.Body>
          </Modal>

          {/* Modal for Deletion */}
          <Modal show={openDeleteModal} onHide={handleCloseDelete} centered>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de Suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currentDevice ? (
                <DevicesForm
                  currentDevice={currentDevice}
                  mode="delete"
                  onDelete={(id) => {
                    //  onDelete(id); // Pass the device ID to the parent handler
                    handleCloseDelete(); // Close the modal after deletion
                  }}
                  onClose={handleCloseDelete}
                />
              ) : (
                <p>Aucun appareil sélectionné pour la suppression.</p>
              )}
            </Modal.Body>
          </Modal>


        </>
      );
    }
  }
];

const DevicesTable: React.FC<DevicesTableProps> = ({ onEdit, onDelete }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default DevicesTable;
