import { useCallback, useEffect, useState } from "react";
import { RoutingData } from "../../../../types/types";

/**
 * **useRoutingDataID**:
 * A custom hook for managing routing data.
 * It handles loading and error states and retrieves data from an external API.
 *
 * @param {number} id - The client ID to fetch routing data for.
 * @returns {Object} - The routing data, loading state, errors, and a setter for the data.
 *
 * @property {RoutingData[]} routingData - The data retrieved from the API or a fallback value.
 * @property {Function} setRoutingData - Function to manually set routing data.
 * @property {boolean} loading - Indicates if the data is loading.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * const { routingData, loading, error } = useRoutingDataID(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <RoutingList data={routingData} />;
 */
const useRoutingDataID = (id: number) => {
  const [routingData, setRoutingData] = useState<RoutingData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchRoutingData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(
        `https://rct-backend.ipercom.io/api/Ipbx/routing/client/${id}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setRoutingData(data);
      setError(null);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données routing data",
        error
      );
      setError("Impossible de récupérer les données routing data.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchRoutingData();
  }, [fetchRoutingData]);

  return {
    routingData,
    setRoutingData,
    loading,
    error
  };
};

export default useRoutingDataID;
