import { Badge, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import PasswordCell from "../../../../components/base/PasswordCell";


interface UserData {
  id: number;
  firstname: string;
  lastname: string;
  extension: string;
  userFunction: string;
  lastActivity?: string;
  mobile?: string;
  fixe?: string;
  displayNumber?: string;
  callStatus?: string;
  userStatus?: string;
  availabilityStatus?: string;
  password: string;
  phoneUsername: string;
  phonePassword: string;
  deviceModel?: string;
  firmwareVersion?: string;
  macAddress?: string;
  ipAddress?: string;
  location?: string;
  sipServer?: string;
  sipPort?: string;
  preferredCodecs?: string;
  provisioningStatus?: string;
}

const PhoneUsersDetails = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState<UserData | null>(null);
  const navigate = useNavigate();
  // const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  // const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  //

  const [error, setError] = useState<string | null>(null);
  //
  // const clientData = useClientData();
  // const serverConfigData = useServerConfigData();
  //
  //
  // const getSubdomainByIdClient = (idClient, clientData, serverConfigData) => {
  //   const client = clientData.find(client => client.id === idClient);
  //   if (client) {
  //     const server = serverConfigData.find(server => server.id === client.idInstance);
  //     return server ? server.subdomain : "Sous domaine non trouvé";
  //   }
  //   return "Client non trouvé";
  // };
  // const subdomain = userData?.id
  //   ? getSubdomainByIdClient(clientFinalId, clientData, serverConfigData)
  //   : "N/A";
  //


  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage
      // if (!token) {
      //   setError('Token not found. Please log in.');
      //   return;
      // }

      try {
        const response = await axios.get(
          `https://rct-backend.ipercom.io/api/Webrtc/phoneuser/${id}`,
          {
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${token}`
            }
          }
        );
        setUserData(response.data);
      } catch (err) {
        // setError('Error fetching user data.');
        console.error(err);
      }
    };

    fetchUserData();
  }, [id]);

  return (
    <Container className="my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">

        {/* Button for going back */}
        <Button variant="primary" onClick={() => navigate(-1)} className="mb-3">
          ← Retour
        </Button>

        <h2>Détails de l'utilisateur téléphone</h2>
        <div>
          <Button
            variant="warning"
            className="me-2"
            onClick={() => setShowEditModal(true)}
          >
            Modifier
          </Button>
          <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
            Supprimer
          </Button>
        </div>
      </div>

      <Row className="mb-4">
        <Col lg={8}>
          <div className="bg-light p-4 rounded">
            <h5 className="mb-4">Informations sur l'utilisateur</h5>
            {error ? (
              <p>{error}</p>
            ) : userData ? (
              <>
                <Row className="mb-3">
                  <Col md={6}>
                    <strong>Utilisateur:</strong>
                    <p>
                      {userData.firstname} {userData.lastname}
                    </p>
                  </Col>
                  <Col md={6}>
                    <strong>Poste interne:</strong>
                    <p>{userData.extension}</p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <strong>Function:</strong>
                    <p>{userData.userFunction}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Activité:</strong>
                    <p>{userData.lastActivity || "N/A"}</p>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={6}>
                    <strong>Téléphone Mobile:</strong>
                    <p>{userData.mobile || "N/A"}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Téléphone Fixe:</strong>
                    <p>{userData.fixe || "N/A"}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Sous domaine instance:</strong>
                    <p>{userData.displayNumber || "N/A"}</p>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={3}>
                    <strong>Statut D’appel:</strong>
                    <Badge
                      bg={
                        userData.callStatus === "online"
                          ? "success"
                          : "secondary"
                      }
                    >
                      {userData.callStatus || "N/A"}
                    </Badge>
                  </Col>
                  <Col md={3}>
                    <strong>Disponibilité :</strong>
                    <Badge
                      bg={
                        userData.userStatus === "connecte"
                          ? "success"
                          : "secondary"
                      }
                    >
                      {userData.userStatus || "N/A"}
                    </Badge>
                  </Col>
                  <Col md={3}>
                    <strong>Statut Utilisateur :</strong>
                    <Badge
                      bg={
                        userData.availabilityStatus === "online"
                          ? "success"
                          : "secondary"
                      }
                    >
                      {userData.availabilityStatus || "N/A"}
                    </Badge>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={3}>
                    <strong>Username SIP:</strong>
                    <p>{userData.phoneUsername}</p>
                  </Col>
                  <Col md={3}>
                    <strong>Password SIP:</strong>
                    <PasswordCell password={userData.phonePassword} />
                  </Col>
                  <Col md={3}>
                    <strong>Domain SIP:</strong>
                    {/*<p>{subdomain}</p>*/}

                  </Col>
                </Row>


              </>
            ) : (
              <p>Loading user data...</p>
            )}
          </div>
        </Col>
        <Col lg={4}>
          <div className="bg-light p-4 rounded">
            <h5 className="mb-4">Informations de Provisioning</h5>
            <p>
              <strong>Modèle d'appareil :</strong>{" "}
              {userData?.deviceModel || "Inconnu"}
            </p>
            <p>
              <strong>Version du firmware :</strong>{" "}
              {userData?.firmwareVersion || "Inconnu"}
            </p>
            <p>
              <strong>Adresse MAC :</strong> {userData?.macAddress || "Inconnu"}
            </p>
            <p>
              <strong>Adresse IP :</strong> {userData?.ipAddress || "Inconnu"}
            </p>
            <p>
              <strong>Emplacement :</strong> {userData?.location || "Inconnu"}
            </p>
          </div>

          <div className="mt-4 bg-light p-4 rounded">
            <h5 className="mb-4">Autres paramètres</h5>
            <p>
              <strong>Serveur SIP :</strong>{" "}
              {userData?.sipServer || "Non configuré"}
            </p>
            <p>
              <strong>Port SIP :</strong> {userData?.sipPort || "Non configuré"}
            </p>
            <p>
              <strong>Codecs préférés :</strong>{" "}
              {userData?.preferredCodecs || "Non configurés"}
            </p>
            <p>
              <strong>Statut du provisionnement :</strong>{" "}
              {userData?.provisioningStatus || "Inconnu"}
            </p>
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col lg={12}>
          <div className="bg-light p-4 rounded">
            <h5 className="mb-4">Les 10 derniers événements liés</h5>
            <p>Aucun événement disponible</p>
          </div>
        </Col>
      </Row>


      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier l'utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*{userDetails && (*/}
          {/*  <UserEditForm*/}
          {/*    user={userDetails}*/}
          {/*    onSave={handleEditSave}*/}
          {/*    onCancel={() => setShowEditModal(false)}*/}
          {/*  />*/}
          {/*)}*/}
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger"
            // onClick={handleDeleteConfirm}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PhoneUsersDetails;
