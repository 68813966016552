import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const InformationCard: React.FC<{ deviceData: any }> = ({ deviceData }) => {
  const details = [
    {
      label: "Nom",
      value: deviceData.name,
      tooltip: "Nom de l'appareil pour identification."
    },
    {
      label: "Constructeur",
      value: deviceData.brand,
      tooltip: "Marque ou fabricant de l'appareil."
    },
    {
      label: "Modèle",
      value: deviceData.deviceModel,
      tooltip: "Modèle spécifique de l'appareil."
    },
    {
      label: "Version du Firmware",
      value: deviceData.firmwareVersion,
      tooltip: "Version du logiciel installé sur l'appareil."
    },
    {
      label: "MAC",
      value: deviceData.macAddress,
      tooltip: "Adresse MAC unique de l'appareil."
    },
    {
      label: "IP Privée",
      value: deviceData.privateIpAddress,
      tooltip: "Adresse IP locale de l'appareil dans le réseau interne."
    },
    {
      label: "IP Publique",
      value: deviceData.publicIpAddress,
      tooltip: "Adresse IP externe visible sur Internet."
    },
    {
      label: "ID Machine",
      value: deviceData.machineId,
      tooltip: "Identifiant unique de la machine pour le suivi."
    },
    {
      label: "Localisation",
      value: deviceData.location,
      tooltip: "Emplacement physique de l'appareil."
    },
    {
      label: "Type d'Appareil",
      value: deviceData.deviceType,
      tooltip: "Catégorie ou type de l'appareil, par exemple, téléphone de bureau."
    },
    {
      label: "Statut de Provisioning",
      value: deviceData.provisioningStatus,
      tooltip: "État du provisioning de l'appareil."
    },
    {
      label: "Statut de l'Appareil",
      value: deviceData.deviceStatus,
      tooltip: "Statut actuel de l'appareil (en ligne, hors ligne, etc.)."
    },
    {
      label: "Date de Création",
      value: new Date(deviceData.createdAt).toLocaleString(),
      tooltip: "Date et heure de création de l'appareil dans le système."
    }
  ];

  return (
    <Card className="bg-light p-4 rounded mb-4">
      <Card.Title>Informations Générales</Card.Title>
      <Row className="gy-0">
        {details.map((detail) => (
          <Col md={6} key={detail.label}>
            <strong>
              {/*{detail.tooltip && (*/}
              {/*  <OverlayTrigger*/}
              {/*    placement="top"*/}
              {/*    overlay={<Tooltip>{detail.tooltip}</Tooltip>}*/}
              {/*  >*/}
              {/*    <FontAwesomeIcon*/}
              {/*      icon={faCircleInfo}*/}
              {/*      className="ms-2 text-info"*/}
              {/*    />*/}
              {/*  </OverlayTrigger>*/}
              {/*)}*/}
              {/*{" "}*/}
              {detail.label} :
            </strong>
            <p className="text-muted">{detail.value || "Non disponible"}</p>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default InformationCard;
