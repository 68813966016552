import { useCallback, useEffect, useState } from "react";
import { PhoneDevicesData } from "./usePhoneDevices";


// export interface PhoneDevicesData {
//   id: number;
//   idClient: number | null;
//   name: string;
//   description?: string;
//   brand: string;
//   deviceModel?: string;
//   firmwareVersion?: string;
//   macAddress: string;
//   machineId: string;
//   privateIpAddress?: string;
//   publicIpAddress?: string;
//   location?: string;
//   deviceType: string;
//   provisioningStatus: string;
//   deviceStatus: string;
//   deviceIdProv?: string;
//   firstUserId?: number; // Added property
//   secondUserId?: number; // Added property
//   provisioningStatus1?: string; // Added property
//   provisioningStatus2?: string; // Added property
//   createdAt?: string; // Added property
//   device?: string;
//   updatedDevice?: string;
//   // Add other properties if necessary
// }


/**
 * **usePhoneDevicesDataID**:
 * A custom hook for managing phone user data from the WebRTC API.
 * It handles loading and error states and retrieves data from an external API.
 *
 * @param {number} id - The client ID to fetch phone user data for.
 * @returns {Object} - The phone user data, loading state, errors, and a setter for the data.
 *
 * @property {PhoneDevicesData[]} phoneDevicesData - The data retrieved from the API or a fallback value.
 * @property {Function} setPhoneDevicesData - Function to manually set phone user data.
 * @property {boolean} loading - Indicates if the data is loading.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * const { phoneDevicesData, loading, error } = usePhoneDevicesDataID(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <PhoneUserList data={phoneDevicesData} />;
 */
const usePhoneDevicesDataID = (id: number) => {
  const [phoneDevicesData, setPhoneDevicesData] = useState<PhoneDevicesData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPhoneDevicesData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/device/client/${id}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setPhoneDevicesData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données phone user data", error);
      setError("Impossible de récupérer les données phone user data.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchPhoneDevicesData();
  }, [fetchPhoneDevicesData]);

  return {
    phoneDevicesData,
    setPhoneDevicesData,
    loading,
    error
  };
};

export default usePhoneDevicesDataID;
