import React, { ChangeEvent, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DevicesTable, { devicesTableColumns } from "./DevicesTable";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
// import usePhoneDevicesDataID, { PhoneDevicesData } from "../../../../hooks/api/ipbx/usePhoneDevicesDataID";
import DevicesForm from "./DevicesForm";
import { PhoneDevicesData } from "../../../../hooks/api/ipbx/usePhoneDevices";
import usePhoneDevicesDataID from "../../../../hooks/api/ipbx/usePhoneDevicesDataID";

const DevicesList = () => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  const { phoneDevicesData, setPhoneDevicesData } = usePhoneDevicesDataID(clientFinalId);
  const [deviceList, setDeviceList] = useState<PhoneDevicesData[]>([]);

  React.useEffect(() => {
    setDeviceList(phoneDevicesData); // Sync the local list with API data
  }, [phoneDevicesData]);

  const table = useAdvanceTable({
    data: deviceList,
    columns: devicesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [openFormModal, setOpenFormModal] = useState(false);
  const [currentDevice, setCurrentDevice] = useState<PhoneDevicesData | undefined>(undefined);

  const handleOpenForm = (device?: PhoneDevicesData) => {
    setCurrentDevice(device || undefined);
    setOpenFormModal(true);
  };

  const handleCloseForm = () => {
    setOpenFormModal(false);
    setCurrentDevice(undefined);
  };

  const handleSaveDevice = (device: PhoneDevicesData) => {
    const isEdit = !!device.id;

    const updatedList = isEdit
      ? deviceList.map((d) => (d.id === device.id ? device : d))
      : [...deviceList, { ...device, id: Date.now() }];

    setDeviceList(updatedList);
    setPhoneDevicesData(updatedList);

    handleCloseForm();
  };

  const handleDeleteDevice = (deviceId: number) => {
    const updatedList = deviceList.filter((device) => device.id !== deviceId);

    setDeviceList(updatedList);
    setPhoneDevicesData(updatedList);
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <AdvanceTableProvider key={JSON.stringify(deviceList)} {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Téléphones</span>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <input
                type="text"
                className="form-control w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
            <Col xs="auto">
              <Button variant="primary" onClick={() => handleOpenForm()}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un téléphone
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <DevicesTable
            onEdit={handleOpenForm}
            onDelete={handleDeleteDevice}
          />
        </div>
      </AdvanceTableProvider>

      <Modal show={openFormModal} onHide={handleCloseForm} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentDevice ? "Modifier Appareil" : "Ajouter Appareil"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DevicesForm
            mode={currentDevice ? "edit" : "add"}
            currentDevice={currentDevice}
            onSave={handleSaveDevice}
            onDelete={handleDeleteDevice}
            onClose={handleCloseForm}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DevicesList;
