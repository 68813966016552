import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import React, { ChangeEvent, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import RetailerUserTable, { userAPITableColumns } from "./RetailerUserTable";
import useRetailerUser from "../../../../hooks/api/security/useRetailerUser";
import UserForm, { UserData } from "./new/UserForm"; // Import only the new UserForm

const RetailerUserList = () => {
  const { userData, setUserData, loading, error } = useRetailerUser();
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const table = useAdvanceTable<UserData>({
    data: userData,
    columns: userAPITableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Utilisateurs</span>
        </h2>
      </div>
      <div className="mb-4">
        <Row className="g-3">
          <Col xs="auto">
            <SearchBox
              className="w-300"
              placeholder="Rechercher"
              onChange={handleSearchInputChange}
            />
          </Col>
          <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
          <Col xs="auto" className="">
            <Button variant="primary" disabled>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Inviter
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="primary" onClick={() => setShowAddUserModal(true)}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Ajouter un utilisateur
            </Button>
          </Col>
        </Row>
      </div>
      {loading && <p>Chargement des données...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {!loading && (
        <AdvanceTableProvider {...table}>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <RetailerUserTable />
          </div>
        </AdvanceTableProvider>
      )}

      {/* Modal for adding a user */}
      <Modal
        show={showAddUserModal}
        onHide={() => setShowAddUserModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserForm
            onSave={(newUserData) => {
              setUserData([...userData, newUserData]); // Update the user list with the new user data
              setShowAddUserModal(false); // Close the modal after saving
            }}
            onCancel={() => setShowAddUserModal(false)} // Close modal when cancel is clicked
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RetailerUserList;
