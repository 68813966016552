import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { PhoneUserData } from "hooks/api/ipbx/usePhoneUser";
import React from "react";
import { useClientData } from "data/iper-x/context/ClientContext";
import { useServerConfigData } from "data/iper-x/context/ServerConfigurationContext";
import PasswordCell from "../../../../components/base/PasswordCell";
import AvatarColor, { Status } from "../../../../components/base/AvatarColor";

export const phoneTableColumns: ColumnDef<PhoneUserData>[] = [
  {
    accessorFn: ({ lastActivity }) => lastActivity,
    id: 'status',
    header: 'Utilisateur',
    cell: ({ row: { original } }) => {
      const { lastActivity, firstname, lastname, id, userStatus, extension } = original;

      const statusMap = {
        connecte: { text: "En ligne", class: "online" },
        deconnecte: { text: "Hors ligne", class: "offline" },
        occupe: { text: "Occupé", class: "calling" }
      } as const;

      const { text: statusText, class: statusClass } =
      statusMap[userStatus as keyof typeof statusMap] || { text: "Inconnu", class: "unknown" };

      const mappedStatusClass = (statusClass as "online" | "offline" | "calling") as Status;


      const avatar = `https://ui-avatars.com/api/?name=${encodeURIComponent(firstname)}+${encodeURIComponent(lastname)}&rounded=true&size=32`;

      return (
        <div className="d-flex align-items-center">
          <AvatarColor
            // src={avatar}
            size="m"
            className="me-1"
            variant="initials"
            firstName={firstname}
            lastName={lastname}
            status={mappedStatusClass}
            style={{ cursor: "pointer" }}
          />
          <div className="d-flex flex-column ms-1">
            <p className="mb-0 fw-bold text-dark">
              <a href={`utilisateurs/details/${id}`} className="text-decoration-none text-dark">
                {firstname} {lastname}
              </a>
            </p>
            <small className="">
              Poste interne : {extension && <small className="text-muted">{extension}</small>}
            </small>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: "15%", fontSize: "11px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }

      },
      cellProps: { className: "ps-2" }
    }
  },
  {
    accessorKey: 'extension',
    header: 'Poste interne',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  // {
  //   accessorFn: (row) => row.idClient,
  //   id: 'idClient',
  //   header: 'Client',
  //   cell: ({ row: { original } }) => {
  //     const clientData = useClientData();
  //     const { idClient } = original;
  //     const client = clientData.find(client => client.id === idClient);
  //     return client ? client.companyName : "Client non trouvé";
  //   },
  //   meta: {
  //     headerProps: { style: { width: '8%', fontSize: '11px' } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   }
  // },
  {
    accessorKey: 'phoneUsername',
    header: "Identifiant SIP",
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: "phonePassword",
    header: "Mot de passe SIP",
    cell: ({ row: { original } }) => (
      <PasswordCell password={original.phonePassword} />
    ),
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'lastActivity',
    header: 'Activité',
    cell: ({ getValue }) => {
      const lastActivity = getValue() as string | number;
      // const formattedTimeAgo = formatDistanceToNow(new Date(lastActivity), { addSuffix: true, locale: fr });

      return <span>{lastActivity}</span>;
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: "test",
    header: 'Info téléphone',
    cell: ({ row: { original } }) => {
      const { infoOrigineTel, infoMarqueTel, infoModeleTel } = original;

      const origine = infoOrigineTel || "Origine du téléphone";
      const marque = infoMarqueTel || "Marque du téléphone";
      const modele = infoModeleTel || "Modèle du téléphone";

      return (
        <div>
          <div>{origine}</div>
          <div>{marque}</div>
          <div>{modele}</div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorFn: (row) => row.idClient,
    id: "idPhoneConfig",
    header: "Sous domaine instance",
    cell: ({ row: { original } }) => {
      const clientData = useClientData();
      const { idClient } = original;

      const client = clientData.find(client => client.id === idClient);
      if (client) {
        const serverConfigData = useServerConfigData();
        const server = serverConfigData.find(server => server.id === client.idInstance);
        return server ? server.subdomain : "Sous domaine non trouvé";
      }
      return "Client non trouvé";
    },
    meta: {
      headerProps: { style: { width: "8%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "userStatus",
    header: "Statut utilisateur",

    meta: {
      headerProps: { style: { width: "8%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: 'callStatus',
    header: 'Statut D’appel',

    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
];

const PhoneTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default PhoneTableAPI;
