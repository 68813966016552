import React from "react";
import { Form } from "react-bootstrap";

const PermissionModeSelector: React.FC<{
  selectedMode: "template" | "custom";
  onChange: (mode: "template" | "custom") => void;
  selectedTemplate: string;
  onTemplateChange: (template: string) => void;
  templates: { key: string; label: string }[];
}> = ({ selectedMode, onChange, selectedTemplate, onTemplateChange, templates }) => {
  return (
    <>
      <Form.Group>
        <Form.Label>Mode d'autorisation</Form.Label>
        <Form.Check
          type="radio"
          label="Utiliser un modèle"
          id="template-mode"
          name="permission-mode"
          checked={selectedMode === "template"}
          onChange={() => onChange("template")}
        />
        <Form.Check
          type="radio"
          label="Autorisations personnalisées"
          id="custom-mode"
          name="permission-mode"
          checked={selectedMode === "custom"}
          onChange={() => onChange("custom")}
        />
      </Form.Group>

      {selectedMode === "template" && (
        <Form.Group className="mt-3">
          <Form.Label>Sélectionnez un modèle</Form.Label>
          <Form.Select
            value={selectedTemplate}
            onChange={(e) => onTemplateChange(e.target.value)}
            required
          >
            <option value="">-- Sélectionnez un modèle --</option>
            {templates.map((template) => (
              <option key={template.key} value={template.key}>
                {template.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
    </>
  );
};

export default PermissionModeSelector;
