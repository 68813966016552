import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { pricingColumnItems } from "../../../data/pricing";
import SocialProfileCard from "components/cards/SocialProfileCard";
import NavbarBottom from "components/modules/social/NavbarBottom";
import { useMainLayoutContext } from "providers/MainLayoutProvider";
import AnalyticsStatsEditor from "../../../components/stats/AnalyticstatsEditor";
import RetailerRanksProgressBar from "../../../components/progress-bars/RetailerRanksProgressBar";
import RetailerPricingColumnItem from "../../../components/pricing-items/RetailerPricingColumnItem";
import LeadListAPI from "./leads/LeadListAPI";
import RetailerClients from "./clients/RetailerClients";
import PageBreadcrumb, { PageBreadcrumbItem } from "../../../components/common/PageBreadcrumb";
import { dummyStats as initialDummyStats } from "../../../data/crm/stats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import useRetailerClients from "../../../hooks/api/retailer/useRetailerClients";

export const revendeurBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Portail Revendeur',
    url: '/dashboard/revendeur'
  }
];

const DashboardRetailer = () => {
  const { setFooterClass } = useMainLayoutContext();

  // Set up state for stats
  const [stats, setStats] = useState(initialDummyStats);
  const { tableData, loading } = useRetailerClients();
  const [licenseObjective, setLicenseObjective] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0); // Updated state for total revenue
  const DOLAPIKEY = localStorage.getItem("DOLAPIKEY");

  useEffect(() => {
    setFooterClass('d-none d-lg-block');
    return () => {
      setFooterClass('');
    };
  }, []);

  // Function to update the total licenses count
  const updateTotalLicenses = () => {
    if (!loading && tableData) {
      const totalLicenses = tableData.length; // Assuming each client has one license; adjust if necessary
      setStats(prevStats => {
        return prevStats.map(stat => {
          if (stat.title === "Total des licences") {
            return { ...stat, emailCount: totalLicenses.toString() };
          } else if (stat.title === "Licences à jour") {
            // Assuming all licenses are up-to-date, you can adjust logic here
            return { ...stat, emailCount: totalLicenses.toString() };
          } else if (stat.title === "Nombre de clients") {
            return { ...stat, emailCount: totalLicenses.toString() };
          } else if (stat.title === "Nouvelle commande") {
            return { ...stat, emailCount: totalLicenses.toString() };
          }

          return stat;
        });
      });
    }
  };

  useEffect(() => {
    updateTotalLicenses();
  }, [tableData, loading]);

  // Fetch orders and calculate total license revenue
  const fetchOrderTotal = async (erpOrderRef: string) => {
    const endpoint = `https://erp.ipercom.io/api/index.php/orders/${erpOrderRef}`;
    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Accept: "application/json",
          DOLAPIKEY: DOLAPIKEY || ""
        }
      });

      // Handle non-200 response
      if (!response.ok) {
        if (response.status === 404) {
          console.warn(`Order with ref ${erpOrderRef} not found.`);
          return 0; // If order not found, return 0 as revenue
        }
        throw new Error(
          `Failed to fetch order data. Status: ${response.status}`
        );
      }

      const orderData = await response.json();
      const totalHt = parseFloat(orderData.total_ht);

      // Ensure total_ht is a valid number
      if (isNaN(totalHt)) {
        console.warn(`Invalid total_ht for order ref ${erpOrderRef}.`);
        return 0; // Return 0 if total_ht is invalid
      }

      return totalHt;
    } catch (error) {
      console.error("Failed to fetch order data:", error);
      return 0; // Return 0 in case of error
    }
  };

  // Sum up all license prices from ERP orders
  const calculateTotalRevenue = async () => {
    if (!loading && tableData) {
      let totalRevenue = 0;
      for (const client of tableData) {
        if (client.erpOrderRef) {
          const orderTotal = await fetchOrderTotal(client.erpOrderRef);
          totalRevenue += orderTotal;
        }
      }
      setLicenseObjective(totalRevenue); // Set total license revenue
      updateObjectiveStat(totalRevenue);
      setTotalRevenue(totalRevenue); // Updated to use state setter
    }
  };

  // Update the objective stat with the total revenue
  const updateObjectiveStat = (totalRevenue: number) => {
    const targetRevenue = 6000; // The target for the year

    setStats(prevStats => {
      return prevStats.map(stat => {
        if (stat.title === "Chiffre d'affaires HT") {
          return {
            ...stat,
            emailCount: `${totalRevenue.toFixed(2)} €` // Add euro symbol
          };
        } else if (stat.title === "Objectif à réaliser HT") {
          const remainingObjective = targetRevenue - totalRevenue;
          return {
            ...stat,
            emailCount: `${remainingObjective > 0 ? remainingObjective.toFixed(2) : 0} €`
          };
        }
        return stat;
      });
    });
  };

  useEffect(() => {
    updateTotalLicenses();
    calculateTotalRevenue(); // Calculate revenue once licenses are fetched
  }, [tableData, loading]);

  return (
    <>
      <Col md={9} xs={12} className="mb-4">
        <PageBreadcrumb items={revendeurBreadcrumbItems} />
        <h2 className="mb-4 text-1100">Portail Revendeur</h2>
      </Col>
      <Row className="gy-3 gx-5 gx-xxl-6">
        <Col lg={5} xl={4}>
          <SocialProfileCard showAbout={true} avatar={""} className="mb-4" />
        </Col>
        <Col md={8} xs={12}>
          <div className="px-3 mb-5 card">
            <AnalyticsStatsEditor stats={stats} />
            <div className="ms-3">
              <RetailerRanksProgressBar current={totalRevenue} />

              <div className="d-flex flex-wrap mt-4 align-items-center">
                <h3 className="me-0">Objectif</h3>
              </div>
              <div className="mt-2">
                <p className="fw-semi-bold mb-0">
                  Obtenez le statut SILVER pour 6000 € HT
                </p>
                <p className="fw-semi-bold mb-0">
                  (Objectif du 1er janvier au 31 décembre de l'année en cours)
                </p>
              </div>
              <div className="d-flex flex-wrap mt-4 align-items-center">
                <h3 className="me-0">Lien de parrainage</h3>
              </div>
              <div className="mt-2 mb-3">
                <p className="fw-semi-bold mb-0">
                  Ajoutez le lien suivant sur votre site web ou envoyez-le par
                  email à vos clients pour associer automatiquement le client à
                  votre compte :
                </p>
                <Link to="https://ipercom.com/a-propos/devenir-revendeur">
                  https://ipercom.com/a-propos/devenir-revendeur
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="px-3 mb-3 px-lg-10 pt-5 pb-5">
        <div className="mb-4">
          <Row>
            <Col xs={12} md={3} className="mt-5">
              <h2>Possibilité d'évolution</h2>
              <p>
                Choisissez l'option qui convient le mieux à votre entreprise.
              </p>
            </Col>
            <Col
              xs={12}
              md={9}
              className="d-flex flex-wrap justify-content-around text-center pe-4"
            >
              {pricingColumnItems.map((item, index) => (
                <RetailerPricingColumnItem key={index} pricing={item} />
              ))}
            </Col>
          </Row>
        </div>
        <div className="card d-none d-md-block pt-n5 mt-2 pb-n4 fs-8">
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 align-content-center border-end">
              Remise sur licences
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0" style={{ color: "#CD1F90" }}>
                <div className="col px-2 py-4" style={{ color: "#B4B7C9" }}>
                  <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">8%</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '1rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">15%</div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">20%</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '1rem', backgroundColor: '#CD1F90' }}
                  ></span>

                </div>
                <span className="col px-2 py-4">25%</span>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 align-content-center border-end">
              Certification expert  (Commerciale et technique)
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0">
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: "#CD1F90" }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 align-content-center border-end">
              Accès au forum partenaires
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0">
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: "#CD1F90" }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 align-content-center border-end">
              Licence iper-x de démonstration accès simultanés {" "}
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0" style={{ color: "#CD1F90" }}>
                <div className="col px-2 py-4">Nfr *4 </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">Nfr *8</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">Nfr *16</div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">Nfr *32</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">Nfr *32</div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 align-content-center border-end">
              Interface revendeur personnalisée 
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0">
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: "#CD1F90" }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 align-content-center border-end">
              Support tickets par/an
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0" style={{ color: "#CD1F90" }}>
                <div className="col px-2 py-4">5</div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">10</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">Support illimité</div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    Support prioritaire & illimité
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">Support prioritaire & illimité</div>

              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 align-content-center border-end">
              Hébergement inclus
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0">
                <div className="col px-2 py-4" style={{ color: "#CD1F90" }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '-1px', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: "#CD1F90" }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 align-content-center border-end">
              Inscription du revendeur sur notre site web
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0">
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: "#CD1F90" }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 align-content-center border-end">
              Lead iper-x
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0">
                <div className="col px-2 py-4" style={{ color: '#B4B7C9' }}>
                  <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: "#CD1F90" }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 border-end ">
              Formation technique et commerciale 2 jours 1250€ HT/jour éligible OPCO Qualiopi (en cours d’agrément)
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0">
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: "#CD1F90" }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 border-end">
              Animations commerciales, campagne marketing, accompagnement
              commercial
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0">
                <div className="col px-2 py-4" style={{ color: '#B4B7C9' }}>
                  <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: '#CD1F90' }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4" style={{ color: "#CD1F90" }}>
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </div>
              </div>
            </div>
          </div>
          <div className="row border-bottom g-0">
            <div className="col-md-3 text-dark fw-semibold ps-4 pe-2 align-content-center border-end">
              Ventes annuelles minimales{' '}
            </div>
            <div className="col-md-9 text-center">
              <div className="row row-cols-7 g-0" style={{ color: "#CD1F90" }}>
                <div className="col px-2 py-4">6 000 € ht/an </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">12 000 € ht/an</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">60 000 € ht/an </div>
                <div
                  className="col position-relative px-2 py-4"
                  style={{ backgroundColor: '#CD1F90', color: 'white' }}
                >
                  <span className="text-light opacity-80">125 000 € ht/an</span>
                  <span
                    className="position-absolute start-0 bottom-100 w-100 rounded-3 rounded-bottom-0"
                    style={{ height: '0rem', backgroundColor: '#CD1F90' }}
                  ></span>
                </div>
                <div className="col px-2 py-4">200 000 € ht/an</div>
              </div>
            </div>
          </div>
        </div>
        <p className="mt-4">
          * Licence Nfr exclusivement commerciale non revendable
        </p>
      </div>

      <LeadListAPI />

      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-6 pb-3 border-y border-300">
        <Row className="gx-6">
          <RetailerClients />
          <Col
            md={6}
            lg={12}
            xl={6}
            className="mb-5 mb-md-3 mb-lg-5 mb-xl-2 mb-xxl-3"
          ></Col>
          <Col md={6} lg={12} xl={6} className="mb-1 mb-sm-0"></Col>
        </Row>
      </div>
      <NavbarBottom active="home" className="d-lg-none" />
    </>
  );
};

export default DashboardRetailer;
