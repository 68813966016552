import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import useTrunkDataID from "hooks/api/ipbx/useTrunkDataID";
import useSdaDataID from "hooks/api/ipbx/useSdaDataID";
import useScheduleDataID from "hooks/api/ipbx/useScheduleDataID";
import usePhoneUserDataID from "../../../../../hooks/api/ipbx/usePhoneUserDataID";
import Select from "react-select";
import { RoutingData } from "../../../../../../types/types";


type RoutingFormProps = {
  tableData: RoutingData[];
  setTableData: React.Dispatch<React.SetStateAction<RoutingData[]>>;
  onClose: () => void;
  initialData?: RoutingData;
};

const RoutingForm: React.FC<RoutingFormProps> = ({
                                                   tableData,
                                                   setTableData,
                                                   onClose,
                                                   initialData
                                                 }) => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId =
    ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  const { trunkData, loading: loadingTrunks } = useTrunkDataID(clientFinalId);
  const { sdaData, loading: loadingSda } = useSdaDataID(clientFinalId);
  const { scheduleData, loading: loadingSchedule } =
    useScheduleDataID(clientFinalId);
  const { phoneUserData, loading: phoneUserLoading } =
    usePhoneUserDataID(clientFinalId);

  const [newRouting, setNewRouting] = useState<RoutingData>(
    initialData || {
      id: 0,
      idClient: clientFinalId || 0,
      idSchedule: 0,
      idTrunk: 0,
      nom: "",
      prefixe: "",
      action: "authorized",
      displayNumber: "",
      state: true,
      users: []
    }
  );

  useEffect(() => {
    if (initialData) {
      const parseUsers = (users: unknown): Array<number | "all"> => {
        if (Array.isArray(users)) {
          return users.filter(
            (user): user is number | "all" =>
              typeof user === "number" || user === "all"
          );
        }
        if (typeof users === "string") {
          return users
            .split(",")
            .map((user) => (user.trim() === "all" ? "all" : Number(user)))
            .filter(
              (user): user is number | "all" =>
                typeof user === "number" || user === "all"
            );
        }
        return [];
      };

      const parsedUsers = parseUsers(initialData.users);

      console.log("Parsed Users:", parsedUsers);
      setNewRouting({
        ...initialData,
        users: parsedUsers.includes("all") ? ["all"] : parsedUsers
      });
      console.log("Updated newRouting:", {
        ...initialData,
        users: parsedUsers.includes("all") ? ["all"] : parsedUsers
      });
    }
  }, [initialData]);



  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewRouting(prevRouting => ({
      ...prevRouting,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const routingSubmit = {
      id: initialData ? initialData.id : undefined,
      idClient: Number(newRouting.idClient),
      idSchedule: Number(newRouting.idSchedule),
      nom: newRouting.nom,
      prefixe: newRouting.prefixe,
      idTrunk: newRouting.idTrunk || null,
      action: newRouting.action || null,
      displayNumber: newRouting.displayNumber || null,
      state: newRouting.state || null,
      users: Array.isArray(newRouting.users)
        ? newRouting.users
          .map(user => (user === "all" ? "all" : user.toString()))
          .join(",")
        : null
    };

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(
        "https://rct-backend.ipercom.io/api/Ipbx/routing",
        {
          method: initialData ? "PUT" : "POST",
          headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(routingSubmit)
        }
      );

      if (response.ok) {
        const responseText = await response.text();
        let data: RoutingData | null = null;

        if (responseText) {
          data = JSON.parse(responseText);
        }

        if (data) {
          if (initialData) {
            setTableData(
              tableData.map(item => (item.id === data.id ? data : item))
            );
          } else {
            setTableData([...tableData, data]);
          }
        }

        onClose();
        window.location.reload();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error handling routing rule", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* Champ pour l'état de la règle */}
      <Form.Group controlId="state" className="align-items-center mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Définir l'état actif ou inactif de la règle sortante.
            </Tooltip>
          }
        >
          <Form.Label>
            État de la Règle{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <div className="d-flex align-items-center">
          <Form.Switch
            name="state"
            checked={newRouting.state}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNewRouting({ ...newRouting, state: e.target.checked })
            }
            className="me-2"
          />
          <span>{newRouting.state ? "Actif" : "Inactif"}</span>
        </div>
      </Form.Group>

      {/* Les autres champs */}
      <Row>
        <Col md={6}>
          <Form.Group controlId="nom">
            <Form.Label>Nom de la règle</Form.Label>
            <Form.Control
              type="text"
              name="nom"
              value={newRouting.nom}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="displayNumber" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Numéro d'affichage au format international (EX : 33518222200)
                </Tooltip>
              }
            >
              <Form.Label>
                Numéro d'affichage{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              as="select"
              name="displayNumber"
              className="form-select"
              value={newRouting.displayNumber}
              onChange={handleChange}
              disabled={loadingTrunks}
            >
              <option value="">Sélectionner un SDA</option>
              {trunkData.map(sda => (
                <optgroup key={sda.id} label={sda.name}>
                  {typeof sda.sdaRegistration === "string"
                    ? sda.sdaRegistration
                      .split(",")
                      .map((registration: string, index: number) => (
                        <option
                          key={`${sda.id}-${index}`}
                          value={registration.trim()}
                        >
                          {registration.trim()}
                        </option>
                      ))
                    : Array.isArray(sda.sdaRegistration)
                      ? (sda.sdaRegistration as string[]).map(
                        (registration: string, index: number) => (
                          <option
                            key={`${sda.id}-${index}`}
                            value={registration}
                          >
                            {registration}
                          </option>
                        )
                      )
                      : null}
                </optgroup>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group controlId="prefixe">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Utiliser un préfixe de numéro au format international E164
                </Tooltip>
              }
            >
              <Form.Label>
                Préfixe de numéro (E164){" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="text"
              name="prefixe"
              value={newRouting.prefixe}
              onChange={handleChange}
              maxLength={20}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="action" className="align-items-center mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>Sélectionnez l'action pour cette règle</Tooltip>
              }
            >
              <Form.Label>
                Action{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <div className="d-flex align-items-center">
              <Form.Switch
                name="action"
                checked={newRouting.action === "authorized"}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewRouting({
                    ...newRouting,
                    action: e.target.checked ? "authorized" : "blocked"
                  })
                }
                className="me-2"
              />
              <span>
                {newRouting.action === "authorized" ? "Autoriser" : "Bloquer"}
              </span>
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Form.Group controlId="idSchedule">
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip>Sélectionnez l'horaire</Tooltip>}
            >
              <Form.Label>
                Horaires de bureau{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              as="select"
              name="idSchedule"
              className="form-select"
              value={newRouting.idSchedule}
              onChange={handleChange}
              disabled={loadingSchedule}
            >
              <option value="">Sélectionner un horaire</option>
              {scheduleData.map(schedule => (
                <option key={schedule.id} value={schedule.id}>
                  {schedule.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="idTrunk">
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip>Sélectionnez le trunk</Tooltip>}
            >
              <Form.Label>
                Trunk{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              as="select"
              name="idTrunk"
              className="form-select  mb-3"
              value={newRouting.idTrunk}
              onChange={handleChange}
              disabled={loadingTrunks}
            >
              <option value="">Sélectionner un trunk</option>
              {trunkData.map(trunk => (
                <option key={trunk.id} value={trunk.id}>
                  {trunk.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId="users" className="mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Sélectionnez les utilisateurs spécifiques pour cette règle ou
              cochez "Appliquer à tous les utilisateurs".
            </Tooltip>
          }
        >
          <Form.Label>
            Utilisateurs{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>

        <Select
          isMulti
          name="users"
          options={[
            { value: "all", label: "Appliquer à tous les utilisateurs" },
            ...(phoneUserData || []).map((user) => ({
              value: user.id,
              label: `${user.firstname} ${user.lastname}`
            })),
          ]}
          value={
            newRouting.users.includes("all")
              ? [{ value: "all", label: "Appliquer à tous les utilisateurs" }]
              : newRouting.users.map((id) => {
                const user = phoneUserData?.find((u) => u.id === id);
                return user
                  ? {
                    value: id,
                    label: `${user.firstname} ${user.lastname}`
                  }
                  : null;
              }).filter(Boolean) // Remove null entries
          }

          // onChange={(selectedOptions) => {
          //   // Map selected options to extract their values, filtering out undefined values
          //   let selectedValues = (selectedOptions || [])
          //     .map((option) => option?.value)
          //     .filter((value): value is number | "all" => value !== undefined);
          //
          //   // If 'all' is preselected and any specific user is selected, remove 'all'
          //   if (selectedValues.includes("all") && selectedValues.length > 1) {
          //     selectedValues = selectedValues.filter((value) => value !== "all");
          //   }
          //
          //   // Update the state with the new selection
          //   setNewRouting({ ...newRouting, users: selectedValues });
          // }}

          onChange={(selectedOptions) => {
            // Map selected options to extract their values, filtering out undefined values
            let selectedValues = (selectedOptions || [])
              .map((option) => option?.value)
              .filter((value): value is number | "all" => value !== undefined);

            // If 'users' is preselected and any specific all is selected, remove it remove
            // if (selectedValues.includes("all") && selectedValues.length > 1) {
            //   selectedValues = selectedValues.filter((value) => value !== "all");
            // }


            // Update the state with the new selection
            setNewRouting({ ...newRouting, users: selectedValues });
          }}


          isDisabled={phoneUserLoading}
          placeholder="Sélectionner les utilisateurs"
          classNamePrefix="react-select"
        />


      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        {initialData ? "Mettre à jour" : "Ajouter"}
      </Button>
    </Form>
  );
};

export default RoutingForm;
