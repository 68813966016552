import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";

// Interface definitions
interface UserConfig {
  theme: string;
  isRTL: boolean;
  role: string; // Ajoutez cette ligne
}

interface ModuleAccess {
  idModule: number;
  slug: string;
  hasAccess: boolean;
  permissions: Permission[];
  features: Feature[];
}

interface Permission {
  idPermission: number;
  slug: string;
  permCreate: boolean | null;
  permRead: boolean;
  permUpdate: boolean | null;
  permDelete: boolean | null;
}

interface Feature {
  idFeature: number;
  slug: string;
  canDo: boolean;
}

interface AuthContextType {
  isAuthenticated: boolean;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  config: UserConfig;
  moduleAccesses: ModuleAccess[];
}

// Create context with default undefined value
const AuthContext = createContext<AuthContextType | undefined>(undefined);

const apiUrl =
  process.env.REACT_APP_API_URL || 'https://rct-backend.ipercom.io/api';

// AuthProvider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    () => !!localStorage.getItem('isAuthenticated')
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<UserConfig>({
    theme: 'light',
    isRTL: false,
    role: localStorage.getItem('userRole') || 'guest'
  });
  const [moduleAccesses, setModuleAccesses] = useState<ModuleAccess[]>([]);

  // Function to store data securely
  const storeDataSecurely = (key: string, data: string) => {
    localStorage.setItem(key, data);
  };

  // Login function
  const login = useCallback(
    async (email: string, password: string): Promise<void> => {
      try {
        const response = await axios.post(
          `${apiUrl}/Account/login`,
          { email, password },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.status === 200) {
          const {
            idUser,
            idCompanies,
            erpThiersId,
            erpUserToken,
            erpUserId,
            avatar,
            group,
            role,
            token,
            moduleAccesses
          } = response.data;

          setIsAuthenticated(true);
          storeDataSecurely('userId', idUser);
          storeDataSecurely('userGroup', group);
          storeDataSecurely('userRole', role);
          storeDataSecurely('isAuthenticated', 'true');
          storeDataSecurely('token', token);
          storeDataSecurely('DOLAPIKEY', erpUserToken); //VPnBn591lOztE1g8H7p06aHR2Akn1ABl
          storeDataSecurely('DOLID', erpThiersId);
          storeDataSecurely('DOLUSERID', erpUserId);
          storeDataSecurely('companyID', idCompanies);
          storeDataSecurely('moduleAccesses', JSON.stringify(moduleAccesses));

          setModuleAccesses(moduleAccesses);

          // Dynamically update the routes
          window.dispatchEvent(new Event('storage'));

          // Redirect based on user group
          // Redirect based on user role

          if (role === 'revendeur') {
            window.location.href = '/dashboard/revendeur';
          } else if (role === 'editeur') {
            window.location.href = '/dashboard/editeur';
          } else if (role === "customer") {
            window.location.href = "/dashboard/client";
          } else {
            window.location.href = '/';
          }
        } else {
          console.error('Unexpected response status:', response.status);
          throw new Error('Login failed');
        }
      } catch (error) {
        console.error('Login failed:', error);
        throw new Error('Login failed');
      }
    },
    []
  );

  // Logout function
  const logout = useCallback(() => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('moduleAccesses');
    setModuleAccesses([]);
    window.location.href = '/login'; // Redirect to login page
  }, []);

  // Synchronize localStorage with state
  useEffect(() => {
    const handleStorageChange = () => {
      const storedModuleAccesses = localStorage.getItem('moduleAccesses');
      if (storedModuleAccesses) {
        setModuleAccesses(JSON.parse(storedModuleAccesses));
      }
      setIsAuthenticated(!!localStorage.getItem('isAuthenticated'));
    };

    window.addEventListener('storage', handleStorageChange);
    setLoading(false);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Memoize context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      loading,
      login,
      logout,
      config,
      moduleAccesses
    }),
    [isAuthenticated, loading, login, logout, config, moduleAccesses]
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// Custom hook to use AuthContext
export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};
