import React, { useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faDownload, faSync } from "@fortawesome/free-solid-svg-icons";
import BLFModal from "./BLFModal";

const GestionCard: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [showBlfModal, setShowBlfModal] = useState(false);

  const handleBlfModalOpen = () => setShowBlfModal(true);
  const handleBlfModalClose = () => setShowBlfModal(false);

  const handleShowModal = (title: string) => {
    setModalTitle(title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalTitle("");
  };

  return (
    <>
      <Card className="bg-light p-4 rounded mt-4">
        <Card.Title>Gestion du téléphone</Card.Title>
        <div
          className="d-grid gap-3"
          style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
        >
          <Button
            variant="light"
            onClick={() => handleShowModal("Redémarrer")}
            className="text-primary border"
          >
            <FontAwesomeIcon icon={faSync} className="me-2" />
            Redémarrer
          </Button>
          <Button
            variant="light"
            onClick={() => handleShowModal("Mise à jour Firmware")}
            className="text-primary border"
          >
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            Mise à jour Firmware
          </Button>
          <Button
            variant="light"
            onClick={() => handleShowModal("Réinitialisation d'usine")}
            className="text-primary border"
          >
            <FontAwesomeIcon icon={faCogs} className="me-2" />
            Réinitialisation d'usine
          </Button>

          <Button
            variant="light"
            className="text-primary border"
            onClick={handleBlfModalOpen}
          >
            <FontAwesomeIcon icon={faCogs} className="me-2" />
            Gérer BLF
          </Button>
        </div>
      </Card>

      <BLFModal
        show={showBlfModal}
        onClose={handleBlfModalClose}
        onSave={(lineKeys) => console.log(lineKeys)}
        // users={[
        //   { id: 1, firstname: "Alice", lastname: "Smith", extension: "1001" },
        //   { id: 2, firstname: "Bob", lastname: "Johnson", extension: "1002" },
        // ]}
      />

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {modalTitle === "Redémarrer" && (
              <p>Confirmez-vous vouloir redémarrer le dispositif ?</p>
            )}
            {modalTitle === "Mise à jour Firmware" && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Version du Firmware</Form.Label>
                  <Form.Control type="text" placeholder="Entrer la version" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Fichier du Firmware</Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
              </>
            )}
            {modalTitle === "Réinitialisation d'usine" && (
              <p>
                Attention : cette action réinitialisera le dispositif à ses
                paramètres d'origine.
              </p>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={() => alert(`${modalTitle} confirmé!`)}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GestionCard;
