import { useCallback, useEffect, useState } from "react";
// import { RetailerClientDataType } from "../../../data/iper-x/retailer/retailerClientsTableData";
// import { UserData } from "../../../../types/user";
// import { UserData } from "./useEditorUser";
import { UserData } from "../../../pages/apps/retailer/users/new/UserForm";

const emptyRetailerUserData: UserData[] = [];


const useEditorUser = () => {
  const [userData, setUserData] = useState<UserData[]>(emptyRetailerUserData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log('Fetching new API data...');
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch(
        'https://rct-backend.ipercom.io/api/User/company/me',
        {
          method: 'GET',
          headers: {
            accept: 'text/plain',
            Authorization: `Bearer ${token}` // Add the token to the Authorization header
          }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setUserData(data);
      setError(null);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données de l'API",
        error
      );
      setError('Impossible de récupérer les données.');
      // setUserData(userTableData); // Fallback to default data
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    userData,
    setUserData,
    loading,
    error
  };
};

export default useEditorUser;
