import classNames from "classnames";
import avatar from "assets/img/team/40x40/avatar.webp";
import { PropsWithChildren } from "react";

export type Size = "7xl" | "6xl" | "8xl" | "5xl" | "4xl" | "3xl" | "2xl" | "xl" | "l" | "m" | "s";
export type Variant = "image" | "name" | "emoji" | "initials";
export type Rounded = "circle" | "square" | "soft";
export type Status =
  "online"
  | "offline"
  | "away"
  | "calling"
  | "unknown"
  | "do-not-disturb"
  | "gold"
  | "ivoire"
  | "platinium"
  | "silver";

interface AvatarProps {
  size: Size;
  src?: string;
  variant?: Variant;
  rounded?: Rounded;
  status?: Status;
  placeholder?: boolean;
  thumbnail?: boolean;
  imageClassName?: string;
  className?: string;
  firstName?: string;
  lastName?: string;
  style?: React.CSSProperties; // Add this line
}

const getInitials = (firstName?: string, lastName?: string) => {
  const firstInitial = firstName?.charAt(0)?.toUpperCase() || "";
  const lastInitial = lastName?.charAt(0)?.toUpperCase() || "";
  return `${firstInitial}${lastInitial}`;
};

const getColorFromInitials = (initials: string) => {
  const charCodeSum = initials
    .split("")
    .reduce((sum, char) => sum + char.charCodeAt(0), 0);
  const colors = ["#F56565", "#ED8936", "#ECC94B", "#48BB78", "#4299E1", "#667EEA", "#9F7AEA", "#ED64A6"];
  return colors[charCodeSum % colors.length];
};

const AvatarColor = ({
                       size,
                       src,
                       variant = "image",
                       rounded = "circle",
                       status,
                       className,
                       imageClassName,
                       thumbnail,
                       placeholder,
                       firstName,
                       lastName
                     }: PropsWithChildren<AvatarProps>) => {
  const initials = getInitials(firstName, lastName);
  const backgroundColor = getColorFromInitials(initials);

  return (
    <div
      className={classNames(className, `avatar avatar-${size}`, {
        [`status-${status}`]: status
      })}
    >
      {variant === "image" && (
        <img
          src={src ? src : avatar}
          alt="avatar"
          className={classNames(imageClassName, {
            "img-thumbnail bg-white": thumbnail,
            "avatar-placeholder": !src || placeholder,
            "rounded-circle": rounded === "circle",
            "rounded-soft": rounded === "soft"
          })}
        />
      )}

      {variant === "initials" && (
        <div
          className={classNames("avatar-initials", {
            "rounded-circle": rounded === "circle",
            "rounded-soft": rounded === "soft"
          })}
          style={{
            backgroundColor,
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <span>{initials}</span>
        </div>
      )}
    </div>
  );
};

export default AvatarColor;
