import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";

interface User {
  id: number;
  firstname: string;
  lastname: string;
  extension: string;
}

interface BLFModalProps {
  show: boolean;
  onClose: () => void;
  onSave: (lineKeys: { keyNumber: number; userId: number | null }[]) => void;
}

const BLFModal: React.FC<BLFModalProps> = ({ show, onClose, onSave }) => {
  const [lineKeys, setLineKeys] = useState<
    { keyNumber: number; userId: number | null }[]
  >([
    { keyNumber: 1, userId: null },
    { keyNumber: 2, userId: null },
    { keyNumber: 3, userId: null },
    { keyNumber: 4, userId: null },
    { keyNumber: 5, userId: null }
  ]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem("token");
      if (!token) {
        setError("Token non trouvé. Veuillez vous connecter.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://rct-backend.ipercom.io/api/Webrtc/phoneuser`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json"
            }
          }
        );

        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des utilisateurs.");
        }

        const data = await response.json();
        setUsers(data);
      } catch (error: any) {
        setError(
          error.message || "Une erreur est survenue lors de la récupération."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleUserChange = (index: number, userId: number | null) => {
    setLineKeys((prev) =>
      prev.map((key, i) =>
        i === index ? { ...key, userId } : key
      )
    );
  };

  const addLineKey = () => {
    setLineKeys((prev) => [
      ...prev,
      { keyNumber: prev.length + 1, userId: null }
    ]);
  };

  const removeLineKey = (index: number) => {
    setLineKeys((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Modal show={show} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Gérer les touches BLF</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Chargement des utilisateurs...</p>
          </div>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : (
          <>
            <Table bordered responsive hover size="sm">
              <thead>
              <tr>
                <th>Numéro de touche</th>
                <th>Utilisateur</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {lineKeys.map((lineKey, index) => (
                <tr key={lineKey.keyNumber}>
                  <td>{lineKey.keyNumber}</td>
                  <td>
                    <Form.Select
                      value={lineKey.userId || ""}
                      onChange={(e) =>
                        handleUserChange(
                          index,
                          e.target.value ? Number(e.target.value) : null
                        )
                      }
                    >
                      <option value="">
                        -- Sélectionnez un utilisateur --
                      </option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.firstname} {user.lastname} ({user.extension})
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => removeLineKey(index)}
                      disabled={lineKeys.length <= 1}
                    >
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
            <Button variant="outline-primary" onClick={addLineKey}>
              Ajouter une touche BLF
            </Button>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="primary" onClick={() => onSave(lineKeys)}>
          Enregistrer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BLFModal;
