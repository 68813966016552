import React, { useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserDetailsForm: React.FC<{
  userData: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ userData, handleInputChange }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Handle file selection state

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  // Handle file input change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]); // Update the selected file
    }
  };

  return (
    <>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="firstname">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              name="firstname"
              value={userData.firstname}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="lastname">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              name="lastname"
              value={userData.lastname}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="mail">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              name="mail"
              value={userData.mail}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="password">
            <Form.Label>Mot de passe</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                value={userData.password}
                onChange={handleInputChange}
                // required
              />
              <InputGroup.Text onClick={toggleShowPassword}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="phone">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={userData.phone}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="mobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="text"
              name="mobile"
              value={userData.mobile}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="avatar">
          <Form.Label>Avatar du profil</Form.Label>
          <Form.Control
            type="file"
            name="avatar"
            onChange={handleFileChange} // Handle file input change
          />
          {selectedFile && <p className="mt-2">Fichier sélectionné : {selectedFile.name}</p>}
        </Form.Group>
      </Row>
    </>
  );
};

export default UserDetailsForm;
