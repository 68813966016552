export const templates = {
  "Client Final": {
    apps: [3, 16], // IPBX and client final
    modules: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61], //48 = portail client
    features: [] // List of feature IDs
  },
  "Utilisateur de base": {
    apps: [1],
    modules: [1, 6, 7], // List of module IDs
    features: [1, 2, 3] // List of feature IDs
  },
  "Responsable": {
    apps: [1],
    modules: [1, 2, 3, 4, 5, 6, 7],
    features: [1, 2, 3, 4, 5, 6, 7, 8]
  },
  "Administrateur": {
    apps: [1],
    modules: [1, 2, 3, 4, 5, 6, 7, 8],
    features: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  }
};

export const apps = [
  { idApps: 1, name: "Revendeur" },
  // { idApps: 2, name: "Editeur" },
  { idApps: 3, name: "Licenses Ipbx" },
  // { idApps: 4, name: "Webrtc" },
  { idApps: 16, name: "Portail Client Final" }
];
