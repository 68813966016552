import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import AvatarColor from "../../../../components/base/AvatarColor";
import { Link } from "react-router-dom";
import RevealDropdown, { RevealDropdownTrigger } from "../../../../components/base/RevealDropdown";
// import { UserData } from "../../../../../types/user";
import UserForm, { UserData } from "./new/UserForm";
import { CellContext, ColumnDef } from "@tanstack/react-table";


export const userAPITableColumns: ColumnDef<UserData>[] = [
  {
    id: "name",
    header: "Nom & Prénom",
    cell: ({ row: { original } }: { row: { original: UserData } }) => {
      const { firstname, lastname, idUsers } = original;
      return (
        <div className="d-flex align-items-center">
          <AvatarColor
            size="m"
            className="me-3"
            variant="initials"
            firstName={firstname}
            lastName={lastname}
            style={{ cursor: "pointer" }}
          />
          <Link to={`details/${idUsers}`} className="text-900">
            {firstname} {lastname}
          </Link>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9 d-flex align-items-center" }
    },
  },
  {
    accessorKey: "mail",
    header: "Email",
    cell: ({ getValue }: CellContext<UserData, unknown>) => {
      const email = getValue() as string | undefined; // Explicitly cast
      return email ? (
        <a href={`mailto:${email}`} className="text-primary">
          {email}
        </a>
      ) : null;
    }

  },

  {
    accessorKey: "phone",
    header: "Téléphone",
    cell: ({ getValue }: CellContext<UserData, unknown>) => {
      const phone = getValue() as string | undefined;
      return phone ? (
        <a href={`tel:${phone}`} className="text-primary">
          {phone}
        </a>
      ) : null;
    }
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    cell: ({ getValue }: CellContext<UserData, unknown>) => {
      const mobile = getValue() as string | undefined;
      return mobile ? (
        <a href={`tel:${mobile}`} className="text-primary">
          {mobile}
        </a>
      ) : null;
    },
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    },
  },

  {
    accessorKey: "userRole.name",
    header: "Statut",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: "leadDropdown",
    header: "Gérer",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedUser, setSelectedUser] = useState<UserData | null>(null);

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (user: UserData) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
      };

      const handleEditClick = (user: UserData) => {
        setSelectedUser(user);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedUser) {
          try {
            const response = await fetch(
              `https://rct-backend.ipercom.io/api/User/${selectedUser.idUsers}`,
              { method: "DELETE", headers: { accept: "text/plain" } }
            );

            if (response.ok) {
              window.location.reload();
            } else {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
          } catch (error) {
            console.error("Error deleting user:", error);
          }
        }
        setShowDeleteModal(false);
      };

      const handleEditSave = async (updatedUser: UserData) => {
        try {
          const token = localStorage.getItem("token");
          const response = await fetch(
            `https://rct-backend.ipercom.io/api/User`,
            {
              method: "PUT",
              headers: {
                accept: "text/plain",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
              },
              body: JSON.stringify(updatedUser)
            }
          );

          if (response.ok) {
            console.log("User updated successfully.");
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error updating user:", error);
        }
        setShowEditModal(false);
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={() => handleEditClick(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteClick(original)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cet utilisateur ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Modifier l'utilisateur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedUser && (
                <UserForm
                  onSave={handleEditSave} // Pass save logic
                  onCancel={() => setShowEditModal(false)} // Close modal on cancel
                  user={selectedUser} // Pass the selected user to the form
                />
              )}
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
];

const RetailerUserTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9 text-center" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default RetailerUserTable;
