import React, { ChangeEvent } from "react";
import { Col, Row } from "react-bootstrap";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import PhoneTableAPI, { phoneTableColumns } from "./PhoneTableAPI";
import { ClientProvider } from "data/iper-x/context/ClientContext";
import useFinalClient from "hooks/api/ipbx/useFinalClient";
import useServerConfig from "hooks/api/ipbx/useServerConfig";
import { ServerConfigProvider } from "data/iper-x/context/ServerConfigurationContext";
import usePhoneUserDataID from "hooks/api/ipbx/usePhoneUserDataID";

const PhoneListAPI = () => {

  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;
  const { phoneUserData, setPhoneUserData, loading, error } = usePhoneUserDataID(clientFinalId);
  const { clientData, loading: clientLoading, error: clientError } = useFinalClient();
  const { serverConfigData, serverConfigLoading, serverConfigError } = useServerConfig();


  // Initialize advance table hook with filtered phone user data
  const table = useAdvanceTable({
    data: phoneUserData,
    columns: phoneTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <ServerConfigProvider serverConfigData={serverConfigData}>
    <ClientProvider clientData={clientData}>
      <div>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Comptes SIP</span>
          </h2>
        </div>

        {/* Search and Filter */}
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
            {/* <Col xs="auto">
              <Button
                variant="primary"
                onClick={() => setShowAddClientModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un téléphone
              </Button>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col> */}
          </Row>
        </div>

        {/* Loading/Error Handling */}
        {loading && <p>Chargement des données...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}

        {/* Table Content */}
        {!loading && (
          <AdvanceTableProvider {...table}>
            <div className="mx-n4 px-2 mx-lg-n6 bg-white border-top border-bottom border-200 position-relative top-1">
              <PhoneTableAPI />
            </div>
          </AdvanceTableProvider>
        )}

        {/* Add Phone Modal */}
        {/* <Modal
          show={showAddClientModal}
          onHide={() => setShowAddClientModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Ajouter un téléphone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddPhoneForm
              tableData={phoneUserData}
              setTableData={setPhoneUserData}
              onClose={() => setShowAddClientModal(false)}
            />
          </Modal.Body>
        </Modal> */}
      </div>
    </ClientProvider>
    </ServerConfigProvider>
  );
};

export default PhoneListAPI;
