import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Nav } from "react-bootstrap";
import ProfileDropdownMenu from "./ProfileDropdownMenu";
import NineDotMenu from "./NineDotMenu";
import { useAppContext } from "providers/AppProvider";
import FeatherIcon from "feather-icons-react";
import { Link, useLocation } from "react-router-dom";
import NotificationDropdownMenu from "./NotificationDropdownMenu";
import ThemeToggler from "components/common/ThemeToggler";
import DropdownSearchBox from "components/common/DropdownSearchBox";
import SearchResult from "components/common/SearchResult";
import classNames from "classnames";
import axios from "axios";
import AvatarColor from "../../base/AvatarColor";

interface UserData {
  firstname: string;
  lastname: string;
  avatar: string;
}

const NavItems: React.FC = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true); // État de chargement
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      axios
        .get('https://rct-backend.ipercom.io/api/Account/info', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          const data: UserData = response.data;
          if (!data.avatar) {
            data.avatar = `https://ui-avatars.com/api/?name=${data.firstname}+${data.lastname}`;
          }
          setUserData(data);
        })
        .catch(() => {
          // Gestion des erreurs si nécessaire
        })
        .finally(() => {
          setLoading(false); // Fin du chargement
        });
    } else {
      setLoading(false); // Pas de token, fin du chargement
    }
  }, []);

  const firstname = userData?.firstname || "";
  const lastname = userData?.lastname || "";
  const statusClass = "online"; // Remplacez par un statut réel si nécessaire

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item>
      <Nav.Item
        className={classNames({
          'd-lg-none':
            navbarPosition === 'vertical' || navbarPosition === 'dual'
        })}
      >
        <Nav.Link onClick={() => setOpenSearchModal(!openSearchModal)}>
          <FeatherIcon icon="search" size={19} style={{ marginBottom: 2 }} />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link h-100"
            variant=""
          >
            <FeatherIcon icon="bell" size={20} />
          </Dropdown.Toggle>
          <NotificationDropdownMenu />
        </Dropdown>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link py-0"
            variant=""
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="2" r="2" fill="currentColor"></circle>
            </svg>
          </Dropdown.Toggle>
          <NineDotMenu />
        </Dropdown>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#e0e0e0",
                    animation: "pulse 1.5s infinite"
                  }}
                ></div>
                {/*<div*/}
                {/*  style={{*/}
                {/*    height: '10px',*/}
                {/*    width: '80px',*/}
                {/*    backgroundColor: '#e0e0e0',*/}
                {/*    borderRadius: '5px',*/}
                {/*    animation: 'pulse 1.5s infinite',*/}
                {/*  }}*/}
                {/*></div>*/}
              </div>
            ) : (
              <AvatarColor
                size="m"
                className="me-1"
                variant="initials"
                firstName={firstname}
                lastName={lastname}
                status={statusClass}
                style={{ cursor: "pointer" }}
              />
            )}
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>

      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NavItems;
