import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddLicenseModal from "./AddLicenseModal";
import { useMainLayoutContext } from "providers/MainLayoutProvider";
import useRetailerClients, { RetailerClientDataType } from "hooks/api/retailer/useRetailerClients";

interface RetailerInfo {
  name: string;
  email: string;
  phone: string;
  address: string;
  partnershipStatus: string;
}

interface ClientInfo {
  name: string;
  email: string;
  phone: string;
  address: string;
  licenseStatus: string;
  licenseExpiry: string;
  retailer: RetailerInfo;
}

interface License {
  idLicences: string;
  clientFinal?: {
    companyName: string;
    id: string;
    idRevendeur: string;
  };
  key: string;
  maxUsers?: number;
  simultaneousCalls?: number;
}

const DashboardEndCustomer = () => {
  const { setFooterClass } = useMainLayoutContext();
  const navigate = useNavigate();

  const { tableData, loading: licensesLoading, error, fetchData } =
    useRetailerClients();
  const [clientInfo, setClientInfo] = useState<ClientInfo | null>(null);
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);
  const [clientLoading, setClientLoading] = useState(true);
  const [selectedLicenseId, setSelectedLicenseId] = useState<string | null>(
    null
  );

  const fetchDataCallback = useCallback(fetchData, []);
  useEffect(() => {
    fetchDataCallback();
  }, [fetchDataCallback]);

  useEffect(() => {
    const companyID = localStorage.getItem("companyID");
    const token = localStorage.getItem("token");

    const fetchClientInfo = async () => {
      try {
        const response = await fetch(
          `https://rct-backend.ipercom.io/api/Company/${companyID}`,
          {
            headers: {
              accept: "text/plain",
              Authorization: `Bearer ${token}`
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch company data.");
        }

        const data = await response.json();
        setClientInfo({
          name: data?.name || "Nom non défini",
          email: data?.email || "Email non défini",
          phone: data?.phone || "Téléphone non défini",
          address: data?.address || "Adresse non définie",
          licenseStatus: data?.licenseStatus || "Statut non défini",
          licenseExpiry: data?.licenseExpiry || "Expiration non définie",
          retailer: {
            name: data?.retailer?.name || "Nom revendeur non défini",
            email: data?.retailer?.email || "Email revendeur non défini",
            phone: data?.retailer?.phone || "Téléphone revendeur non défini",
            address: data?.retailer?.address || "Adresse revendeur non définie",
            partnershipStatus:
              data?.retailer?.partnershipStatus || "Partenariat non défini"
          },
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
        setClientLoading(false);
      }
    };

    fetchClientInfo();
  }, []);

  useEffect(() => {
    setFooterClass("d-none d-lg-block");
    return () => {
      setFooterClass("");
    };
  }, [setFooterClass]);

  useEffect(() => {
    const storedSelection = localStorage.getItem("ipbx_access");
    if (storedSelection) {
      const parsedSelection = JSON.parse(storedSelection);
      if (parsedSelection.length > 0) {
        setSelectedLicenseId(parsedSelection[0].id_licence);
      }
    }
  }, []);

  const handleLicenseClick = (license: RetailerClientDataType) => {
    setSelectedLicenseId(String(license.idLicences));
    const selectedLicense = {
      ref_ipbx: license.clientFinal?.companyName || "default_ipbx",
      id_client_final: license.clientFinal?.id || "default_id_client_final",
      id_licence: String(license.idLicences) || "default_id_licence",
      revendeur_id: license.clientFinal?.idRevendeur || "default_revendeur_id"
    };

    localStorage.setItem("ipbx_access", JSON.stringify([selectedLicense]));
  };

  const confirmSelection = () => {
    navigate("/ipbx/dashboard");
    window.location.reload();
  };

  if (licensesLoading || clientLoading) {
    return <div className="text-center my-5">Chargement des données...</div>;
  }

  if (error) {
    return <div className="text-center my-5 text-danger">{error}</div>;
  }

  return (
    <div className="container my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Portail Client</h2>
        <Button variant="success" onClick={() => setShowAddLicenseModal(true)}>
          Ajouter une licence
        </Button>
      </div>

      <Row>
        <Col lg={8}>
          <div className="bg-light p-4 rounded">
            <h5 className="mb-4">Informations sur le Client</h5>
            <p>
              <strong>Nom:</strong> {clientInfo?.name}
            </p>
            <p>
              <strong>Email:</strong> {clientInfo?.email}
            </p>
            <p>
              <strong>Téléphone:</strong> {clientInfo?.phone}
            </p>
            <p>
              <strong>Adresse:</strong> {clientInfo?.address}
            </p>
            <p>
              <strong>Statut de la licence:</strong> {clientInfo?.licenseStatus}
            </p>
            <p>
              <strong>Expiration de la licence:</strong>{" "}
              {clientInfo?.licenseExpiry}
            </p>
          </div>
        </Col>
        <Col lg={4}>
          <div className="bg-light p-4 rounded">
            <h5 className="mb-4">Informations sur le Revendeur</h5>
            <p>
              <strong>Nom:</strong> {clientInfo?.retailer?.name}
            </p>
            <p>
              <strong>Email:</strong> {clientInfo?.retailer?.email}
            </p>
            <p>
              <strong>Téléphone:</strong> {clientInfo?.retailer?.phone}
            </p>
            <p>
              <strong>Adresse:</strong> {clientInfo?.retailer?.address}
            </p>
            <p>
              <strong>Statut du Partenariat:</strong>{" "}
              {clientInfo?.retailer?.partnershipStatus}
            </p>
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <div className="d-flex flex-wrap">
            {tableData.map(license => (
              <Card
                key={license.idLicences}
                className={`m-2 p-3 ${
                  selectedLicenseId === String(license.idLicences)
                    ? "border-success"
                    : "border-light"
                }`}
                style={{ cursor: "pointer", width: "18rem" }}
                onClick={() => handleLicenseClick(license)}
              >
                <Card.Body>
                  <Card.Title>
                    {license.clientFinal?.companyName || "Non défini"}
                  </Card.Title>
                  <Card.Text>
                    <strong>Clé:</strong> {license.key}
                  </Card.Text>
                  <Card.Text>
                    <strong>Max Utilisateurs:</strong>{" "}
                    {license.maxUsers || "Non défini"}
                  </Card.Text>
                  <Card.Text>
                    <strong>Appels Simultanés:</strong>{" "}
                    {license.simultaneousCalls || "Non défini"}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
          {selectedLicenseId && (
            <Button
              className="mt-3"
              variant="primary"
              onClick={confirmSelection}
            >
              Confirmer la sélection
            </Button>
          )}
        </Col>
      </Row>

      {/* Add License Modal */}
      <AddLicenseModal
        show={showAddLicenseModal}
        handleClose={() => setShowAddLicenseModal(false)}
        handleSave={license => {
          alert(`Licence ajoutée: ${JSON.stringify(license)}`);
          setShowAddLicenseModal(false);
          fetchData(); // Refresh the licenses after adding a new one
        }}
      />
    </div>
  );
};

export default DashboardEndCustomer;
