import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import InformationCard from "./InformationCard";
import ProvisioningCard from "./ProvisioningCard";
import GestionCard from "./GestionCard";
import axios from "axios";
import DevicesForm from "./DevicesForm";

const DeviceDetails: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [deviceData, setDeviceData] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [currentDevice, setCurrentDevice] = useState<any | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchDeviceData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("Token non trouvé. Veuillez vous connecter.");
      return;
    }

    try {
      const response = await axios.get(
        `https://rct-backend.ipercom.io/api/Ipbx/device/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDeviceData(response.data);
    } catch (err) {
      console.error("Erreur lors de la récupération des détails :", err);
      setError("Erreur lors de la récupération des détails de l'appareil.");
    }
  };

  useEffect(() => {
    fetchDeviceData();
  }, [id]);

  const handleEditDevice = () => {
    setCurrentDevice(deviceData);
    setShowEditModal(true);
  };

  const handleDeleteDevice = () => {
    setCurrentDevice(deviceData);
    setShowDeleteModal(true);
  };

  const handleSaveDevice = (updatedDevice: any) => {
    setDeviceData(updatedDevice);
    setShowEditModal(false);
    setCurrentDevice(null);
  };

  const handleDeleteConfirm = () => {
    // Logic to handle device deletion
    setShowDeleteModal(false);
    navigate(-1); // Redirect to previous page
  };

  const handleProvisioningSave = (userId: number | null, field: "firstUserId" | "secondUserId") => {
    setDeviceData((prev: any) => ({
      ...prev,
      [field]: userId
    }));
  };

  if (error) {
    return (
      <Container>
        <p className="text-danger">{error}</p>
        <Button onClick={() => navigate(-1)}>Retour</Button>
      </Container>
    );
  }

  if (!deviceData) {
    return (
      <Container>
        <p>Chargement des détails de l'appareil...</p>
      </Container>
    );
  }

  return (
    <Container className="my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Button variant="primary" onClick={() => navigate(-1)}>
          ← Retour
        </Button>
        <h2>Détails de l'Appareil</h2>
        <div>
          <Button className="me-2" variant="warning" onClick={handleEditDevice}>
            Modifier
          </Button>
          <Button variant="danger" onClick={handleDeleteDevice}>
            Supprimer
          </Button>
        </div>
      </div>

      <Row className="gy-4">
        <Col lg={6}>
          <InformationCard deviceData={deviceData} />
        </Col>
        <Col lg={6}>
          <ProvisioningCard deviceData={deviceData} onSave={handleProvisioningSave} />
          <GestionCard />
        </Col>
      </Row>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Modifier l'Appareil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DevicesForm
            currentDevice={currentDevice}
            mode="edit"
            onSave={handleSaveDevice}
            onClose={() => setShowEditModal(false)}
          />
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Êtes-vous sûr de vouloir supprimer l'appareil{" "}
            <strong>{currentDevice?.name}</strong> ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DeviceDetails;
