import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import UserDetailsForm from "./UserDetailsForm";
import PermissionsAccordion from "./PermissionsAccordion";
import PermissionModeSelector from "./PermissionModeSelector";
import useAppModules from "hooks/api/security/useAppModules";
import useModuleFeatures from "hooks/api/security/useModuleFeatures";
import { apps, templates } from "./templates";

interface Template {
  modules: number[];
  features: number[];
}

// const templates: Record<string, Template> = {
//   "Utilisateur de base": { modules: [1, 2], features: [1, 2] },
//   Responsable: { modules: [3, 4], features: [3, 4] },
//   Administrateur: { modules: [5, 6], features: [5, 6] },
// };

export interface UserData {
  idUsers?: number;
  firstname?: string;
  lastname?: string;
  mail?: string;
  phone?: string;
  mobile?: string;
  userRole?: {
    id: number;
    name: string;
    slug: string;
  };
  company?: {
    id: number;
    name: string;
    ceoName: string;
  };
  erpThiersId?: number;
  erpUserToken?: string;
  contact_id?: number;
  yealinkSiteId?: string;
  fanvilSiteId?: string;
  password?: string;
  moduleAccess: { idModule: number; hasAccess: boolean }[];
  features: { idFeature: number; canDo: boolean }[];
}

const UserForm: React.FC<{ onSave: (user: UserData) => void; onCancel: () => void; user?: UserData }> = ({
                                                                                                           onSave,
                                                                                                           onCancel,
                                                                                                           user
                                                                                                         }) => {
  const [userData, setUserData] = useState<UserData>({
    firstname: "",
    lastname: "",
    mail: "",
    moduleAccess: [],
    features: []
  });

  const [selectedMode, setSelectedMode] = useState<"template" | "custom">("template");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedAppId, setSelectedAppId] = useState<number>(1); // Initialisation avec l'ID 1


  // Fetching modules and features
  const { appModules, loading: loadingModules, error: errorModules } = useAppModules();
  const { moduleFeatures, loading: loadingFeatures, error: errorFeatures } = useModuleFeatures();

  // Filtered modules and features based on selected application
  const filteredModules = selectedAppId
    ? appModules.filter((module) => module.idApps === selectedAppId)
    : appModules;

  const filteredFeatures = selectedAppId
    ? moduleFeatures.filter((feature) =>
      filteredModules.some((module) => module.idModule === feature.idModule)
    )
    : moduleFeatures;

  useEffect(() => {
    if (user) setUserData(user);
  }, [user]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleTemplateChange = (template: string) => {
    const selected = templates[template as keyof typeof templates];

    if (selected) {
      setUserData((prev: UserData) => ({
        ...prev,
        moduleAccess: selected.modules.map((idModule: number) => ({ idModule, hasAccess: true })),
        features: selected.features.map((idFeature: number) => ({ idFeature, canDo: true }))
      }));
      setSelectedTemplate(template);
    }
  };

  if (loadingModules || loadingFeatures) {
    return <p>Chargement des données...</p>;
  }

  if (errorModules || errorFeatures) {
    return <p>Erreur lors du chargement des modules ou fonctionnalités.</p>;
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        const updatedUserData = { ...userData };

        // Remove the password field if it is empty
        if (!updatedUserData.password) {
          delete updatedUserData.password;
        }

        onSave(updatedUserData);
      }}
    >
      <UserDetailsForm userData={userData} handleInputChange={handleInputChange} />

      {/*<Form.Group controlId="selectApp" className="mb-3">*/}
      {/*  <Form.Label>Sélectionnez une application</Form.Label>*/}
      {/*  <Form.Control*/}
      {/*    as="select"*/}
      {/*    value={selectedAppId}*/}
      {/*    onChange={(e) => setSelectedAppId(e.target.value ? Number(e.target.value) : 0)}*/}

      {/*  >*/}
      {/*    <option value="">Select an Application</option>*/}
      {/*    {apps.map((module) => (*/}
      {/*      <option key={module.idApps} value={module.idApps}>*/}
      {/*        {module.name}*/}
      {/*      </option>*/}
      {/*    ))}*/}
      {/*  </Form.Control>*/}
      {/*</Form.Group>*/}

      <PermissionModeSelector
        selectedMode={selectedMode}
        onChange={setSelectedMode}
        selectedTemplate={selectedTemplate}
        onTemplateChange={handleTemplateChange}
        templates={Object.keys(templates).map((key) => ({ key, label: key }))}
      />

      <PermissionsAccordion
        appModules={filteredModules}
        moduleFeatures={filteredFeatures}
        userData={userData}
        isCustomizing={selectedMode === "custom"}
        handleModuleAccessChange={(idModule, hasAccess) =>
          setUserData((prev: UserData) => ({
            ...prev,
            moduleAccess: prev.moduleAccess
              .filter((m) => m.idModule !== idModule)
              .concat({ idModule, hasAccess }),
          }))
        }
        handleFeatureChange={(idFeature, canDo) =>
          setUserData((prev: UserData) => ({
            ...prev,
            features: prev.features
              .filter((f) => f.idFeature !== idFeature)
              .concat({ idFeature, canDo }),
          }))
        }
      />

      <Button type="submit">Enregistrer</Button>
      <Button variant="secondary" onClick={onCancel} className="ms-2">
        Annuler
      </Button>
    </Form>
  );
};

export default UserForm;
