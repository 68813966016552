import { faCircleInfo, faEye, faEyeSlash, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TrunkData } from "hooks/api/ipbx/useTrunk";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

type TrunkFormProps = {
  tableData: TrunkData[];
  setTableData: React.Dispatch<React.SetStateAction<TrunkData[]>>;
  onClose: () => void;
  initialData?: TrunkData;
};

const countryOptions = [
  { name: "États-Unis", prefix: "1" },
  { name: "Canada", prefix: "1" },
  { name: "France", prefix: "33" },
  { name: "Royaume-Uni", prefix: "44" },
  { name: "Allemagne", prefix: "49" },
  { name: "Australie", prefix: "61" },
  { name: "Japon", prefix: "81" },
  { name: "Inde", prefix: "91" },
  { name: "Chine", prefix: "86" },
  { name: "Russie", prefix: "7" },
  { name: "Espagne", prefix: "34" },
  { name: "Italie", prefix: "39" },
  { name: "Suisse", prefix: "41" },
  { name: "Belgique", prefix: "32" },
  { name: "Brésil", prefix: "55" },
  { name: "Mexique", prefix: "52" },
  { name: "Afrique du Sud", prefix: "27" },
  { name: "Argentine", prefix: "54" },
  { name: "Autriche", prefix: "43" },
  { name: "Égypte", prefix: "20" },
  { name: "Irlande", prefix: "353" },
  { name: "Pays-Bas", prefix: "31" },
  { name: "Suède", prefix: "46" },
  { name: "Norvège", prefix: "47" },
  { name: "Danemark", prefix: "45" },
  { name: "Finlande", prefix: "358" },
  { name: "Grèce", prefix: "30" },
  { name: "Portugal", prefix: "351" },
  { name: "Nouvelle-Zélande", prefix: "64" },
  { name: "Corée du Sud", prefix: "82" },
  { name: "Arabie Saoudite", prefix: "966" },
  { name: "Turquie", prefix: "90" },
  { name: "Israël", prefix: "972" },
  { name: "Émirats Arabes Unis", prefix: "971" },
  { name: "Hongrie", prefix: "36" },
  { name: "Pologne", prefix: "48" },
  { name: "Roumanie", prefix: "40" },
  { name: "Slovaquie", prefix: "421" },
  { name: "Tchéquie", prefix: "420" },
  { name: "Venezuela", prefix: "58" },
  { name: "Colombie", prefix: "57" },
  { name: "Chili", prefix: "56" },
  { name: "Singapour", prefix: "65" },
  { name: "Thaïlande", prefix: "66" },
  { name: "Malaisie", prefix: "60" },
  { name: "Indonésie", prefix: "62" },
  { name: "Philippines", prefix: "63" },
  { name: "Pakistan", prefix: "92" },
  { name: "Bangladesh", prefix: "880" },
  { name: "Vietnam", prefix: "84" },
  { name: "Nigeria", prefix: "234" },
  { name: "Kenya", prefix: "254" },
  { name: "Ghana", prefix: "233" },
  { name: "Ouganda", prefix: "256" },
  { name: "Tanzanie", prefix: "255" },
  { name: "Maroc", prefix: "212" },
  { name: "Tunisie", prefix: "216" },
  { name: "Algérie", prefix: "213" }
];

const TrunkSIPForm: React.FC<TrunkFormProps> = ({
                                                  tableData,
                                                  setTableData,
                                                  onClose,
                                                  initialData
                                                }) => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId =
    ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  const [newTrunk, setNewTrunk] = useState<TrunkData>(initialData || {
    id: 0,
    name: '',
    idClient: clientFinalId || null,
    username: '',
    password: '',
    server: '',
    caps: 0,
    currentCall: 1,
    displayName: '',
    displayNumber: '',
    prefixe: "",
    ringDuration: 20,
    withRegistration: true,
    sdaRegistration: '',
    stateRegister: true,
    state: true,
    nbLiveCalls: 0
  });

  useEffect(() => {
    if (initialData) {
      setNewTrunk(initialData);
    }
  }, [initialData]);

  const [showPassword, setShowPassword] = useState(false);
  const [sdaInput, setSdaInput] = useState<string>("");
  const [sdaList, setSdaList] = useState<string[]>([]);
  const [sdaError, setSdaError] = useState<string | null>(null);

  const [selectedCountry, setSelectedCountry] = useState({
    name: "France",
    prefix: "33"
  });

  // Validation du nom de trunk
  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValidName = /^[a-zA-Z0-9_]*$/.test(value);
    if (isValidName) {
      setNewTrunk(prevTrunk => ({ ...prevTrunk, name: value }));
    }
  };

  const addSdaToList = () => {
    const isValidSDA = /^\d{11}$/.test(sdaInput.trim());
    if (isValidSDA) {
      setSdaList(prevSdaList => {
        const newSdaList = [...prevSdaList, sdaInput];
        setNewTrunk(prevTrunk => ({
          ...prevTrunk,
          sdaRegistration: newSdaList.join(",")
        }));
        return newSdaList;
      });
      setSdaInput("");
      setSdaError(null);
    } else {
      setSdaError("Le numéro SDA doit contenir exactement 11 chiffres.");
    }
  };

  const handleDeleteSda = (sdaToDelete: string) => {
    setSdaList(prevSdaList => {
      const newSdaList = prevSdaList.filter(sda => sda !== sdaToDelete);
      setNewTrunk(prevTrunk => ({
        ...prevTrunk,
        sdaRegistration: newSdaList.join(",")
      }));
      return newSdaList;
    });
  };

  const toggleShowPassword = () => setShowPassword(prevShow => !prevShow);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewTrunk(prevTrunk => ({
      ...prevTrunk,
      [name]: value === "" ? null : value
    }));
  };

  // Handle selection from dropdown
  const handleCountrySelect = (prefix: string, name: string) => {
    setSelectedCountry({ name, prefix });
    setNewTrunk(prevTrunk => ({ ...prevTrunk, prefixe: prefix }));
  };

  const handlePrefixChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputPrefix = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setNewTrunk(prevTrunk => ({ ...prevTrunk, prefixe: inputPrefix }));

    const matchedCountry = countryOptions.find(
      option => option.prefix === inputPrefix
    );
    if (matchedCountry) {
      setSelectedCountry(matchedCountry);
    } else {
      setSelectedCountry({ name: "Inconnu", prefix: inputPrefix });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token not found");

      const method = initialData ? "PUT" : "POST";
      const endpoint = "https://rct-backend.ipercom.io/api/Ipbx/trunk";

      const payload = initialData ? { ...newTrunk, id: newTrunk.id } : newTrunk;

      const response = await fetch(endpoint, {
        method,
        headers: {
          accept: "text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        const data: TrunkData = response.status === 204 ? newTrunk : await response.json();

        // Force tableData state to update with a new reference
        setTableData(prevData => {
          const updatedData = initialData
            ? prevData.map(item => (item.id === data.id ? data : item)) // Update edited entry
            : [...prevData, data]; // Add new entry
          console.log("Updated tableData:", updatedData); // Debugging line
          return [...updatedData]; // Ensuring a new array reference
        });

        onClose(); // Close the modal or form
        window.location.reload();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout/modification du trunk", error);
    }
  };


  return (
    <Form onSubmit={handleSubmit}>
      {/* Section: Informations générales */}
      <h5 className="mt-3">Informations générales</h5>

      <Row>
        <Col md={6}>
          <Form.Group controlId="name">
            <Form.Label>
              Nom du Trunk{" "}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Le nom ne doit contenir que des lettres, chiffres et "_"
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={newTrunk.name}
              onChange={handleNameChange}
              required
              disabled={!!initialData} // Disable if initialData is present (edit mode)
            />
          </Form.Group>

        </Col>
        <Col md={6}>
          <Form.Group controlId="server">
            <Form.Label>
              Serveur Opérateur{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    Indiquez le nom de domaine ou l'adresse IP du serveur
                    opérateur (ex. 192.168.1.1 ou sip.example.com). Assurez-vous
                    que l'adresse est valide et accessible. Évitez les
                    caractères spéciaux autres que les points dans les adresses
                    de domaine.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              type="text"
              name="server"
              value={newTrunk.server}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Section: Connexion */}
      <h5 className="mt-4">
        Informations de connexion Trunk{" "}
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              <p>
                <strong>Identifiant du Trunk :</strong> Utilisé pour
                l'authentification de la connexion trunk avec l'opérateur.
                Assurez-vous que cet identifiant est unique et fourni par votre
                opérateur.
              </p>
              <p>
                <strong>Mot de passe du Trunk :</strong> Mot de passe sécurisé
                pour l'authentification de la connexion trunk. Assurez-vous que
                ce mot de passe est fourni par votre opérateur.
              </p>
            </Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={faCircleInfo}
            style={{ cursor: "pointer", marginLeft: "4px" }}
          />
        </OverlayTrigger>
      </h5>

      <Row>
        <Col md={6}>
          <Form.Group controlId="username">
            <Form.Label>Identifiant de connexion Trunk</Form.Label>
            <Form.Control
              type="text"
              name="username"
              value={newTrunk.username || ""}
              onChange={handleChange}
              placeholder="Identifiant fourni par l'opérateur"
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="password">
            <Form.Label>Mot de passe de connexion Trunk</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                value={newTrunk.password || ""}
                onChange={handleChange}
                placeholder="Mot de passe sécurisé du trunk"
              />
              <InputGroup.Text
                onClick={toggleShowPassword}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      {/* Section: Configuration des appels */}
      <h5 className="mt-4">Configuration des appels</h5>

      <Row>
        <Col md={6}>
          <Form.Group controlId="currentCall">
            <Form.Label>Nb maximal d'appels simultanés</Form.Label>
            <Form.Control
              type="number"
              name="currentCall"
              value={newTrunk.currentCall}
              onChange={handleChange}
              min={1}
              max={100}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="ringDuration">
            <Form.Label>Durée de sonnerie (en secondes)</Form.Label>
            <Form.Control
              type="number"
              name="ringDuration"
              value={newTrunk.ringDuration || ""}
              onChange={handleChange}
              min={0}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Section: Affichage et préfixe */}
      <h5 className="mt-4">Affichage et préfixe</h5>
      <Row>
        <Col md={6}>
          <Form.Group controlId="displayName">
            <Form.Label>Nom affiché lors des appels</Form.Label>
            <Form.Control
              type="text"
              name="displayName"
              value={newTrunk.displayName || ""}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="displayNumber">
            <Form.Label>Numéro affiché lors des appels</Form.Label>
            <Form.Control
              type="text"
              name="displayNumber"
              value={newTrunk.displayNumber || ""}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId="prefixe">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Pour certains opérateurs, un préfixe peut être ajouté avant le
              numéro (optionnel).
              <br />
              Unyc : +
              <br />
              Alphalink : 00
            </Tooltip>
          }
        >
          <Form.Label>
            Préfixe de numéro (E164){" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <Form.Control
          type="text"
          name="prefixe"
          value={newTrunk.prefixe}
          onChange={handleChange}
          maxLength={20}
          // required
        />
      </Form.Group>

      {/*<Form.Group controlId="prefixe">*/}
      {/*  <Form.Label>Sélectionnez ou entrez un préfixe téléphonique</Form.Label>*/}
      {/*  <InputGroup>*/}
      {/*    <DropdownButton*/}
      {/*      title={`${selectedCountry.name} (${selectedCountry.prefix})`}*/}
      {/*      id="countryPrefixDropdown"*/}
      {/*    >*/}
      {/*      {countryOptions.map(country => (*/}
      {/*        <Dropdown.Item*/}
      {/*          key={country.prefix}*/}
      {/*          onClick={() =>*/}
      {/*            handleCountrySelect(country.prefix, country.name)*/}
      {/*          }*/}
      {/*        >*/}
      {/*          {country.name} (+{country.prefix})*/}
      {/*        </Dropdown.Item>*/}
      {/*      ))}*/}
      {/*    </DropdownButton>*/}
      {/*    <Form.Control*/}
      {/*      type="text"*/}
      {/*      placeholder="Entrez un préfixe"*/}
      {/*      value={newTrunk.prefixe || '33'}*/}
      {/*      onChange={handlePrefixChange}*/}
      {/*    />*/}
      {/*  </InputGroup>*/}
      {/*</Form.Group>*/}

      {/* Section: Configuration SDA */}
      <h5 className="mt-4">Numéros SDA</h5>
      <Form.Group controlId="sdaRegistration">
        <Form.Label>
          Ajouter un SDA (format international)
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip>
                <p>
                  <strong>Numéro affiché lors d’un appel sortant :</strong>{" "}
                  Utilisé pour afficher un numéro spécifique lors des appels
                  émis.
                </p>
                <p>
                  <strong>Exemple :</strong> Le format recommandé est{" "}
                  <code>33518222200</code> pour un numéro français, sans espaces
                  ni caractères spéciaux.
                </p>
              </Tooltip>
            }
          >
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </OverlayTrigger>
        </Form.Label>

        <InputGroup>
          <Form.Control
            type="text"
            value={sdaInput}
            onChange={e => setSdaInput(e.target.value)}
            placeholder="Ex: 33518222200"
          />
          <Button onClick={addSdaToList} variant="success">
            Ajouter
          </Button>
        </InputGroup>
        {sdaError && <Form.Text className="text-danger">{sdaError}</Form.Text>}
      </Form.Group>
      {sdaList.length > 0 && (
        <ul className="mt-2">
          {sdaList.map((sda, index) => (
            <li key={index} className="d-flex align-items-center">
              {sda}{" "}
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => handleDeleteSda(sda)}
                style={{ cursor: "pointer", marginLeft: "8px" }}
              />
            </li>
          ))}
        </ul>
      )}

      {/* Section: États et statuts */}
      <h5 className="mt-4">États et statuts</h5>
      <Row>
        <Col md={3}>
          <Form.Group controlId="state" className="align-items-center mb-2">
            <Form.Label>État du trunk :</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Switch
                name="state"
                checked={newTrunk.state}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewTrunk({
                    ...newTrunk,
                    state: e.target.checked
                  })
                }
                className="me-2"
              />
              <span>{newTrunk.state ? "Actif" : "Inactif"}</span>
            </div>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group
            controlId="stateRegister"
            className="align-items-center mb-2"
          >
            <Form.Label>Statut d'enregistrement (REGISTER) :</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Switch
                name="state"
                checked={newTrunk.stateRegister}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewTrunk({
                    ...newTrunk,
                    stateRegister: e.target.checked
                  })
                }
                className="me-2"
              />
              <span>{newTrunk.stateRegister ? "Actif" : "Inactif"}</span>
            </div>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="withRegistration">
            <div className="d-flex align-items-center">
              <Form.Label className="me-2">
                Activer l'enregistrement du trunk
              </Form.Label>
              <OverlayTrigger
                placement="auto"
                overlay={
                  <Tooltip>
                    <p>
                      <strong>Activer l'enregistrement du Trunk :</strong> Cette
                      option configure le PBX pour maintenir une connexion
                      active avec le serveur opérateur (ex. Unyc).
                    </p>
                    <p>
                      <strong>Avec enregistrement :</strong> Le PBX s'enregistre
                      périodiquement sur le serveur opérateur, permettant au
                      serveur de savoir que le trunk est disponible pour
                      recevoir des appels entrants.
                    </p>
                    <p>
                      <strong>Sans enregistrement :</strong> Le trunk n'établit
                      pas de connexion active sur le serveur opérateur et
                      n'attend pas d'appels entrants. Cela peut être utile si le
                      trunk est configuré uniquement pour des appels sortants ou
                      dans des environnements où l'opérateur ne nécessite pas de
                      registration.
                    </p>
                    <p>
                      <em>Note :</em> L'activation ou la désactivation de
                      l'enregistrement dépend des exigences de votre opérateur
                      et de votre configuration PBX. Certains opérateurs comme
                      Unyc nécessitent l'enregistrement pour gérer les appels
                      entrants.
                    </p>
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </OverlayTrigger>
            </div>
            <Form.Switch
              name="withRegistration"
              checked={newTrunk.withRegistration}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNewTrunk({
                  ...newTrunk,
                  withRegistration: e.target.checked
                })
              }
              className="mt-2"
            />
          </Form.Group>
        </Col>
      </Row>

      <Button variant="primary" type="submit" className="mt-4">
        {initialData ? "Enregistrer les modifications" : "Ajouter"}
      </Button>
    </Form>
  );
};

export default TrunkSIPForm;
