import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";

interface User {
  id: number;
  firstname: string;
  lastname: string;
  extension: string;
}

interface ProvisioningCardProps {
  deviceData: any;
  onSave: (userId: number | null, field: "firstUserId" | "secondUserId") => void;
}

const ProvisioningCard: React.FC<ProvisioningCardProps> = ({ deviceData, onSave }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalField, setModalField] = useState<"firstUserId" | "secondUserId" | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem("token");
      if (!token) {
        setError("Token non trouvé. Veuillez vous connecter.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`https://rct-backend.ipercom.io/api/Webrtc/phoneuser`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
          }
        });

        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des utilisateurs.");
        }

        const data = await response.json();
        setUsers(data);
      } catch (error: any) {
        setError(error.message || "Une erreur est survenue.");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const getUserDetails = (userId: number | null) => {
    if (!userId) return null;
    const user = users.find((u) => u.id === userId);
    return user ? `${user.firstname} ${user.lastname} (${user.extension})` : null;
  };

  const handleEdit = (field: "firstUserId" | "secondUserId") => {
    setModalField(field);
    setSelectedUserId(deviceData[field]);
    setShowModal(true);
  };

  const handleSave = () => {
    if (modalField) {
      onSave(selectedUserId, modalField);
    }
    setShowModal(false);
  };

  const renderCard = (userId: number | null, label: string, field: "firstUserId" | "secondUserId") => {
    const userDetails = getUserDetails(userId);

    return (
      <Col md={6} key={field}>
        <Card
          className={`bg-light p- rounded mt-1 card ${userId ? "border-info" : "border-danger"}`}
          style={{ borderWidth: "2px", backgroundColor: "#f7f9fc" }}
        >
          <Card.Body>
            <Card.Title>{label}</Card.Title>
            {userId ? (
              <p>{userDetails}</p>
            ) : (
              <p className="text-danger">Non enregistré</p>
            )}
            <Button variant="link" onClick={() => handleEdit(field)}>
              Modifier
            </Button>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <Row>
        {renderCard(deviceData.firstUserId, "Utilisateur 1", "firstUserId")}
        {renderCard(deviceData.secondUserId, "Utilisateur 2", "secondUserId")}
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Modifier Utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Chargement des utilisateurs...</p>
          ) : error ? (
            <p className="text-danger">{error}</p>
          ) : (
            <Form>
              <Form.Group controlId="userSelect">
                <Form.Label>Sélectionner un utilisateur</Form.Label>
                <Form.Select
                  value={selectedUserId || ""}
                  onChange={(e) => setSelectedUserId(Number(e.target.value))}
                >
                  <option value="">-- Sélectionner un utilisateur --</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.firstname} {user.lastname} ({user.extension})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProvisioningCard;
