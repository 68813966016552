import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, ProgressBar, Row, Spinner } from "react-bootstrap";

interface ThirdParty {
  id: string;
  name: string;
}

interface Contact {
  lastname: string;
  firstname: string;
  phone_pro: string;
  phone_mobile: string;
  address: string;
  zip: string;
  town: string;
  socid: string;
  email: string;
}

interface User {
  login: string;
  password: string;
  gender: string;
  email: string;
  office_phone: string;
  user_mobile: string;
  job: string;
  civility_code: string;
  birth: string;
  address: string;
  zip: string;
  town: string;
}

const RetailerCreate: React.FC = () => {
  const [thirdParties, setThirdParties] = useState<ThirdParty[]>([]);
  const [selectedThirdParty, setSelectedThirdParty] = useState<string>(''); // Will be set automatically
  const [contact, setContact] = useState<Contact>({
    lastname: '',
    firstname: '',
    phone_pro: '',
    phone_mobile: '',
    address: '',
    zip: '',
    town: '',
    socid: '',
    email: '',
  });
  const [user, setUser] = useState<User>({
    login: '',
    password: '',
    gender: '',
    email: '',
    office_phone: '',
    user_mobile: '',
    job: '',
    civility_code: '',
    birth: '',
    address: '',
    zip: '',
    town: '',
  });
  const [isLoading, setIsLoading] = useState(false); // To handle loading state
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loadingStep, setLoadingStep] = useState<number>(0); // To track loading steps

  useEffect(() => {
    const fetchThirdParties = async () => {
      try {
        const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
        const response = await fetch('https://erp.ipercom.io/api/index.php/thirdparties', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'DOLAPIKEY': DOLAPIKEY || '',
          },
        });
        const data = await response.json();
        setThirdParties(data.map((party: any) => ({ id: party.id, name: party.name })));

        const storedDOLID = localStorage.getItem('DOLID');
        if (storedDOLID) {
          setSelectedThirdParty(storedDOLID);
          setContact(prevContact => ({
            ...prevContact,
            socid: storedDOLID,
          }));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données third-parties:", error);
      }
    };

    fetchThirdParties();
  }, []);

  const generatePassword = (length: number) => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const generateUsername = (firstname: string, lastname: string) => {
    const sanitizedFirstname = firstname.replace(/\s/g, '').toLowerCase();
    const sanitizedLastname = lastname.replace(/\s/g, '').toLowerCase();
    return `${sanitizedFirstname}.${sanitizedLastname}`;
  };

  const handleContactChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setContact(prevContact => ({
      ...prevContact,
      [name]: value,
    }));

    setUser(prevUser => ({
      ...prevUser,
      [name]: value,
    }));

    if (name === 'firstname' || name === 'lastname') {
      const generatedUsername = generateUsername(
        name === 'firstname' ? value : contact.firstname,
        name === 'lastname' ? value : contact.lastname
      );
      setUser(prevUser => ({
        ...prevUser,
        login: generatedUsername,
        password: generatePassword(12),
      }));
    }
  };

  const handleUserChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const createContact = async () => {
    try {
      const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
      const response = await fetch('https://erp.ipercom.io/api/index.php/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'DOLAPIKEY': 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl',
        },
        body: JSON.stringify(contact),
      });

      const contactData = await response.json();

      if (!response.ok) {
        console.error('Contact creation error:', contactData);
        throw new Error(contactData.error?.message || 'Failed to create contact');
      }

      return contactData; // Return the created contact data
    } catch (error) {
      throw new Error(`Contact creation failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  const createUser = async (contactData: any) => {
    try {
      const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');

      const requestBody = {
        login: user.login,
        password: user.password,
        email: user.email,
        office_phone: user.office_phone,
        user_mobile: user.user_mobile,
        gender: user.gender,
        civility_code: user.civility_code,
        job: user.job,
        birth: user.birth,
        address: user.address,
        zip: user.zip,
        town: user.town,
      };

      const response = await fetch(`https://erp.ipercom.io/api/index.php/contacts/${contactData}/createUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'DOLAPIKEY': DOLAPIKEY || '',
        },
        body: JSON.stringify(requestBody),
      });

      const userData = await response.json();

      if (!response.ok) {
        console.error('Error creating user:', userData);
        throw new Error(userData.error?.message || 'Failed to create user');
      }

      return userData;
    } catch (error) {
      // throw new Error(`User creation failed: ${error.message}`);
    }
  };

  const addUserToGroup = async (userData: any) => {
    try {
      const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
      const groupId = 5;
      const response = await fetch(`https://erp.ipercom.io/api/index.php/users/${userData}/setGroup/${groupId}?entity=1`, {
        method: 'GET',
        headers: {
          'DOLAPIKEY': DOLAPIKEY || '',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to add user to reseller group');
      }
    } catch (error) {
      throw new Error(`Adding user to group failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsLoading(true);
    setLoadingStep(0);

    try {
      setLoadingStep(1); // Step 1: Creating Contact
      const contactData = await createContact();

      setLoadingStep(2); // Step 2: Creating User
      const userData = await createUser(contactData);

      setLoadingStep(3); // Step 3: Adding User to Group
      await addUserToGroup(userData);

      setSuccess('Utilisateur revendeur créé avec succès.');
      setContact({
        lastname: '',
        firstname: '',
        phone_pro: '',
        phone_mobile: '',
        address: '',
        zip: '',
        town: '',
        socid: '',
        email: '',
      });
      setUser({
        login: '',
        password: '',
        gender: '',
        email: '',
        office_phone: '',
        user_mobile: '',
        job: '',
        civility_code: '',
        birth: '',
        address: '',
        zip: '',
        town: '',
      });
    } catch (error) {
      setError(`Erreur: ${error instanceof Error ? error.message : 'Inconnue'}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow-sm mb-4">
            <Card.Header className="bg-light">
              <h2 className="text-center">Créer un utilisateur</h2>
            </Card.Header>
            <Card.Body>
              {success && <Alert variant="success">{success}</Alert>}
              {error && <Alert variant="danger">{error}</Alert>}

              <Form onSubmit={onSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="firstname">
                      <Form.Label>Prénom</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstname"
                        value={contact.firstname}
                        onChange={handleContactChange}
                        required
                        className="mb-3"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="lastname">
                      <Form.Label>Nom</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastname"
                        value={contact.lastname}
                        onChange={handleContactChange}
                        required
                        className="mb-3"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="phone_pro">
                      <Form.Label>Téléphone professionnel</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone_pro"
                        value={contact.phone_pro}
                        onChange={handleContactChange}
                        className="mb-3"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="phone_mobile">
                      <Form.Label>Téléphone mobile</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone_mobile"
                        value={contact.phone_mobile}
                        onChange={handleContactChange}
                        className="mb-3"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={contact.email}
                    onChange={handleContactChange}
                    required
                    className="mb-3"
                  />
                </Form.Group>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="address">
                      <Form.Label>Adresse</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={contact.address}
                        onChange={handleContactChange}
                        className="mb-3"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="zip">
                      <Form.Label>Code postal</Form.Label>
                      <Form.Control
                        type="text"
                        name="zip"
                        value={contact.zip}
                        onChange={handleContactChange}
                        className="mb-3"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="town">
                  <Form.Label>Ville</Form.Label>
                  <Form.Control
                    type="text"
                    name="town"
                    value={contact.town}
                    onChange={handleContactChange}
                    className="mb-3"
                  />
                </Form.Group>

                <h3>Utilisateur</h3>
                <Form.Group controlId="login">
                  <Form.Label>Nom d'utilisateur</Form.Label>
                  <Form.Control type="text" name="login" value={user.login} readOnly className="mb-3" />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control
                    type="text"
                    name="password"
                    value={user.password}
                    readOnly
                    className="mb-3"
                  />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isLoading} className="w-100">
                  {isLoading ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    'Créer utilisateur'
                  )}
                </Button>

                {isLoading && (
                  <ProgressBar
                    now={(loadingStep / 3) * 100}
                    label={
                      loadingStep === 1
                        ? 'Création du contact...'
                        : loadingStep === 2
                          ? 'Création de l’utilisateur...'
                          : 'Ajout de l’utilisateur au groupe...'
                    }
                    className="mt-3"
                  />
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RetailerCreate;
